import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { message, Modal, Button, Input, Form as AntdForm, InputNumber } from 'antd';

import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import { generatePdfAndUpload } from '../../QuotePdf';
//import ShopifyBuyButton from '../../BookPage/ShopifyBuyButton';
import { AddToCart } from '../../BookPage/BookPage.styles';

interface ShopifyWrapperProps {
  variantId: string;
  product: any;
}

const ShopifyWrapper: React.FC<ShopifyWrapperProps> = ({ variantId, product }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = AntdForm.useForm();

  const { data: quoteData } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getQuoteData, {
    onError: (error) => {
      message.error(`Error in fetching quote details - ${error.message || 'Unexpected Error'}`);
    },
  });
  // TODO: Move the code to fetch user cart and user data to a more appropriate place
  // const { data, refetch: refetchCart } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserCart, {
  //   onError: (error) => {
  //     message.error(`Error fetching user cart - ${error.message || 'Unexpected Error'}`);
  //   },
  // });

  const { data: userMetaData, refetch: refetchCart } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserMetaData, {
    onError: (error) => {
      message.error(`Error fetching user data - ${error.message || 'Unexpected Error'}`);
    },
  });

  const [addToCart] = useMutation(
    gqlSchema.ShopifySchema.mutations.ADD_TO_CART_SHOPIFY,
    {
      onCompleted: () => {
        message.success('Product added to cart successfully!');
        refetchCart?.(); // Ensure refetchCart exists before calling it
      },
      onError: (error) => {
        message.error(`Error adding product to cart - ${error.message || 'Unexpected Error'}`);
      },
    }
  );


  // const [updateCartItem] = useMutation(
  //     gqlSchema.ShopifySchema.mutations.UPDATE_CART_ITEM_SHOPIFY,
  //     {
  //         onCompleted: () => {
  //             message.success('Cart item updated successfully!');
  //             refetchCart();
  //         },
  //         onError: (error) => {
  //             message.error(`Error updating cart item - ${error.message || 'Unexpected Error'}`);
  //         },
  //     }
  // );

  // const [sendQuoteEmail] = useMutation<{ sendQuoteEmail: { pdfUrl: string; metadata: string } }>(
  //   gqlSchema.ShopifySchema.mutations.SEND_QUOTE_EMAIL,
  //   {
  //     onCompleted: () => {
  //       message.success('Quote request sent successfully!');
  //       setTimeout(() => {
  //         const temp = false;
  //         temp && history.push('/bookquoteList');
  //       }, 5000);
  //     },
  //     onError: (error) => {
  //       message.error(`Error sending quote request - ${error.message || 'Unexpected Error'}`);
  //     },
  //   },
  // );

  const [sendQuoteEmailSingle] = useMutation<{ sendQuoteEmail: { pdfUrl: string, metadata: string, email: string, product: string, totalPrice: number,reference:string } }>(
    gqlSchema.ShopifySchema.mutations.SEND_QUOTE_EMAIL_SINGLE,
    {
      onCompleted: () => {
        message.success('Quote request sent successfully!');
        setTimeout(() => {
          const temp = false;
          temp && history.push('/bookquoteList');
        }, 5000);
      },
      onError: (error) => {
        message.error(`Error sending quote request - ${error.message || 'Unexpected Error'}`);
      },
    },
  );

  const [saveQuoteToS3] = useMutation(gqlSchema.ShopifySchema.mutations.UPLOAD_QUOTE, {
    onCompleted: (data) => {
      console.log('File uploaded successfully:', data);
    },
    onError: (err) => {
      message.error('There was an error uploading your file: ' + err.message);
    },
  });

  const handleRequestQuoteButtonClick = () => {
    setIsModalVisible(true);
  };
  let quoteDetails = quoteData?.getQuoteData;

  const handleData = (quantity: number) => {
    const unitPrice = Number(product.price);
    const subtotal = unitPrice * quantity;
    const shipping = Number((subtotal / 10).toFixed(2));
    const total = subtotal + shipping; 

    return {
        items: [
            {
                title: product.title,
                sku: product.barcode,
                quantity: quantity,
                unitPrice: unitPrice,
            }
        ],
        subtotal: subtotal,
        shipping: shipping,
        total: total,
    };
};


  const handleRequestQuote = async (formData: any) => {
    setLoading(true); // Show loading state

    try {
      if (!quoteDetails) {
        throw new Error('Quote details are not available.');
      }

      const quoteNumber=Math.random().toString(36).substring(2, 8).toUpperCase();

      const metadata = `${formData.city || 'N/A'} 
                    ${formData.state || 'N/A'} ${formData.country || 'N/A'} ${formData.zipcode || 'N/A'} `;
      quoteDetails = {
        ...quoteDetails,
        teacherName: formData?.name,
        email: formData?.email,
        shippingAddress: metadata,
        reference:quoteNumber,
      };

      const pdfBlob = await generatePdfAndUpload({ ...quoteDetails, ...handleData(formData.quantity) });

      if (!(pdfBlob instanceof Blob)) {
        throw new Error('Generated PDF is not a Blob.');
      }

      const file = new File([pdfBlob], 'quote.pdf', { type: 'application/pdf' });
      // const pdfUrl1 = URL.createObjectURL(pdfBlob);

      // // Trigger the download
      // const link1 = document.createElement('a');
      // link1.href = pdfUrl1;
      // link1.download = 'quote.pdf';
      // document.body.appendChild(link1);
      // link1.click();
      // document.body.removeChild(link1);

      // // Cleanup the URL
      // URL.revokeObjectURL(pdfUrl1);

      const link = await saveQuoteToS3({ variables: { file } });
      const pdfUrl = link?.data?.saveQuoteToS3;
      const productData = {
        title: product.title,
        price: product.price,
        barcode: product.barcode,
        quantity: formData.quantity,
        description: product.description

      }
      const total = parseFloat((parseFloat(product.price) * formData.quantity * 1.1).toFixed(2));
      await sendQuoteEmailSingle({ variables: { pdfUrl, metadata: JSON.stringify(formData), email: quoteDetails.email, product: JSON.stringify(productData), totalPrice: total,reference:quoteNumber } });
      message.success('Quote request sent successfully!');
      setIsModalVisible(false);  // Close the modal after submission
    } catch (err) {
      message.error(`Error sending quote request: ${err || 'Unexpected Error'}`);
    } finally {
      setLoading(false);
    }
  };

  const addToCartCb = async () => {
    setLoading(true);
    try {
      await addToCart({
        variables: {
          variantId: [
            {
              merchandiseId: `gid://shopify/ProductVariant/${variantId}`,
              quantity: 1,
            },
          ],
        },
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleUpdateCartItem = (shopifyProductId: string, quantity: number) => {
  //     if (shopifyProductId && quantity >= 0) {
  //         updateCartItem({ variables: { productId: shopifyProductId, quantity } });
  //     }
  // };

  // const handleRemoveCartItem = (shopifyProductId: string) => {
  //     updateCartItem({ variables: { productId: shopifyProductId, quantity: 0 } });
  // };

  // const increaseQuantity = (shopifyProductId: string) => {
  //     const item = userCart.cartItem.find((item: any) => item.shopifyId === shopifyProductId);
  //     if (item) {
  //         handleUpdateCartItem(shopifyProductId, item.quantity + 1);
  //     }
  // };

  // const decreaseQuantity = (shopifyProductId: string) => {
  //     const item = userCart.cartItem.find((item: any) => item.shopifyId === shopifyProductId);
  //     if (item && item.quantity > 1) {
  //         handleUpdateCartItem(shopifyProductId, item.quantity - 1);
  //     } else if (item && item.quantity === 1) {
  //         handleRemoveCartItem(shopifyProductId);
  //     } else {
  //         message.error('Quantity cannot be less than 1');
  //     }
  // };

  useEffect(() => {
    if (userMetaData) {
      form.setFieldsValue({
        name: userMetaData?.getUserMetaData.name || '',
        email: userMetaData?.getUserMetaData.email,
        street: userMetaData?.getUserMetaData.street || '',
        state: userMetaData?.getUserMetaData.state || '',
        city: userMetaData?.getUserMetaData.city || '',
        zipCode: userMetaData?.getUserMetaData.zipCode || '',
        quantity:1,
      });
    }
  }, [userMetaData, form]);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1em' }}>
        {/* <ShopifyBuyButton
                productId={productId}
                addToCart={addToCartCb}
                cartData={userCart}
                decreaseQuantity={decreaseQuantity}
                increaseQuantity={increaseQuantity}
                handleRequestQuote={handleRequestQuoteButtonClick}
                handleUpdateCartItem={handleUpdateCartItem}
            /> */}
        <div>
          <AddToCart
            style={{
              fontSize: '14px',
            }}
            onClick={addToCartCb}
            disabled={loading}
          >
            {loading ? 'Adding...' : 'Add to Cart'}
          </AddToCart>
        </div>

        <Button
          onClick={handleRequestQuoteButtonClick}
          style={{
            fontSize: '14px',
          }}
          disabled={loading}
        >
          Request A Quote
        </Button>
      </div>
      <Modal
        title="Update Shipping Address"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <AntdForm form={form} onFinish={handleRequestQuote} layout="vertical">
          <AntdForm.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
            <Input placeholder="Enter your name" type="text" />
          </AntdForm.Item>
          <AntdForm.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter a valid email address',
              },
            ]}
          >
            <Input placeholder="Type your email here..." disabled />
          </AntdForm.Item>
          <AntdForm.Item label="Street Address" name="street">
            <Input placeholder="Enter your street address" type="text" />
          </AntdForm.Item>
          <AntdForm.Item label="City" name="city">
            <Input placeholder="Enter your city" type="text" />
          </AntdForm.Item>
          <AntdForm.Item label="State" name="state">
            <Input placeholder="Enter your state" type="text" />
          </AntdForm.Item>
          <AntdForm.Item label="Zip Code" name="zipCode">
            <Input placeholder="Enter your zip code" type="number" />
          </AntdForm.Item>
          <AntdForm.Item
            label="Quantity"
            name="quantity"
          >
            <InputNumber
              min={1}
              placeholder="Enter your quantity"
              style={{ width: "100%" }}
            />
          </AntdForm.Item>
          <AntdForm.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              block
              style={{
                width: '50%',
                margin: '0.5em auto 0',
                textAlign: 'center',
                display: 'block',
              }}
            >
              Submit
            </Button>
          </AntdForm.Item>
        </AntdForm>
      </Modal>
    </div>
  );
};

export default ShopifyWrapper;
