import React, { useMemo } from 'react';
import { GiSandsOfTime } from 'react-icons/gi';
import { FiBarChart, FiBook, FiDownloadCloud, FiEdit, FiGrid, FiUser, FiUsers } from 'react-icons/fi';
import * as S from './styles';
import { BsBook } from 'react-icons/bs';
import { BiBuildingHouse } from 'react-icons/bi';

interface MenuProps {
  menuVisible: boolean;
  isWideScreen: boolean;
  currentPath: string;
  onSelectMenu: (item: any) => void;
}

interface MenuAdiAdminProps {
  menuVisible: boolean;
  currentPath: string;
  isWideScreen?: boolean;
  onSelectMenu: (item: any) => void;
}


export const RenderMenuStudent: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(() => {
    return (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-studentmenu-item-myduedates"
          $menuVisible={menuVisible}
          icon={<GiSandsOfTime />}
          key="/student-dashboard/due-dates"
        >
          My Due Dates
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-studentmenu-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/student-dashboard"
        >
          Dashboard
        </S.MenuItem>
        
      </S.Menu>
    );
  }, [menuVisible, currentPath, onSelectMenu, isWideScreen]);
};

export const RenderMenuGoogleTeacher: React.FC<MenuProps & { user: any; isGoogleTeacher: boolean }> = ({
  menuVisible,
  currentPath,
  onSelectMenu,
  isGoogleTeacher,
  isWideScreen,
}) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/googleclassroom-teacher-dashboard">
          Dashboard
        </S.MenuItem>
        {isGoogleTeacher && (
          <S.MenuItem icon={<FiDownloadCloud />} key={'/sync-list'}>
            Sync
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/googleclassroom-teacher-assignment"
        >
          Assignment
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-investigation"
          $menuVisible={menuVisible}
          icon={<BsBook />}
          key="/teacher-investigation"
        >
          Lessons
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/teacher-assessment"
        >
          Assessments
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen]
  );
};

export const RenderMenuGoogleStudent: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/googleclassroom-students-dashboard"
        >
          Dashboard
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/googleclassroom-students-assignmentduedate"
        >
          Assignment Due Dates
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen],
  );
};

export const RenderMenuCanvasTeacher: React.FC<MenuProps & { isCanvasTeacher: boolean }> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen, isCanvasTeacher }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/canvas-teacher-dashboard">
          Dashboard
        </S.MenuItem>
        {isCanvasTeacher && (
          <S.MenuItem icon={<FiDownloadCloud />} key={'/sync-list'}>
            Sync
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/canvas-teacher-assignment"
        >
          Assignment
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-investigation"
          $menuVisible={menuVisible}
          icon={<BsBook />}
          key="/teacher-investigation"
        >
          Lessons
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/teacher-assessment"
        >
          Assessments
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen, isCanvasTeacher],
  );
};

export const RenderMenuCanvasStudent: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/canvas-students-dashboard"
        >
          Dashboard
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/canvas-students-assignmentduedate"
        >
          Assignment Due Dates
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen],
  );
};

export const RenderMenuTeacher: React.FC<MenuProps & { user: any; isWriter: boolean; isTeacher: boolean }> = ({
  menuVisible,
  currentPath,
  onSelectMenu,
  user,
  isWriter,
  isTeacher,
  isWideScreen,
}) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={isWideScreen ? 'horizontal' : 'inline'}
      >
        {user.subscription?.customLibrary && isWriter && (
          <S.MenuItem $menuVisible={menuVisible} icon={<FiBook />} key="/organization-library">
            Organization Library
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-investigation"
          $menuVisible={menuVisible}
          icon={<BsBook />}
          key="/teacher-investigation"
        >
          Lessons
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/teacher-assessment"
        >
          Assessments
        </S.MenuItem>
        {['Clever', 'Classlink'].includes(user.source) && (
          <S.MenuItem icon={<FiDownloadCloud />} key="/sync-list">
            Sync
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-teacher-insights"
          $menuVisible={menuVisible}
          icon={<FiBarChart />}
          key="/teacher-insights/investigations"
        >
          Insights
        </S.MenuItem>
        {isTeacher && (
          <S.MenuItem
            data-cy="shared-authenticatedheader-teacher-item-teacher-users"
            $menuVisible={menuVisible}
            icon={<FiUser />}
            key="/teacher-users"
          >
            Users
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/teacher-dashboard"
        >
          Dashboard
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isWriter, isTeacher, isWideScreen],
  );
};

export const RenderMenuTeacherAssistant: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-teacher-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiGrid />}
          key="/teacher-dashboard"
        >
          Dashboard
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, currentPath, onSelectMenu, isWideScreen],
  );
};

export const RenderMenuWriter: React.FC<MenuProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem $menuVisible={menuVisible} icon={<FiBook />} key="/organization-library">
          Organization Library
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, currentPath, onSelectMenu, isWideScreen],
  );
};

export const RenderMenuAdiAdmin: React.FC<MenuAdiAdminProps> = ({ menuVisible, currentPath, onSelectMenu, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-investigations"
          $menuVisible={menuVisible}
          icon={<FiBook />}
          key="/adi-investigations"
        >
          Lessons
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-organizations"
          $menuVisible={menuVisible}
          icon={<BiBuildingHouse />}
          key="/adi-organizations"
        >
          Organizations
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-assessments"
          $menuVisible={menuVisible}
          icon={<FiEdit />}
          key="/adi-assessments"
        >
          Assessments
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-users"
          $menuVisible={menuVisible}
          icon={<FiUsers />}
          key="/adi-users"
        >
          Users
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-dashboard"
          $menuVisible={menuVisible}
          icon={<BsBook />}
          key="/bookadmin"
        >
          Books
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-adimenu-item-dashboard"
          $menuVisible={menuVisible}
          icon={<FiBook />}
          key="/adi-dashboard"
        >
          Dashboard
        </S.MenuItem>
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, isWideScreen],
  );
};

export const RenderMenuOrganizationAdmin: React.FC<MenuProps & { user: any }> = ({ menuVisible, currentPath, onSelectMenu, user, isWideScreen }) => {
  return useMemo(
    () => (
      <S.Menu
        $menuVisible={menuVisible}
        onSelect={onSelectMenu}
        selectedKeys={[currentPath]}
        mode={menuVisible ? 'inline' : 'horizontal'}
      >
        {user.subscription?.customLibrary && (
          <S.MenuItem
            data-cy="shared-authenticatedheader-profilemenu-item-organization-library"
            $menuVisible={menuVisible}
            icon={<FiBook />}
            key="/organization-library"
          >
            Organization Library
          </S.MenuItem>
        )}
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-organization-users"
          $menuVisible={menuVisible}
          icon={<FiUsers />}
          key="/organization-users"
        >
          Users
        </S.MenuItem>
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-organization-details"
          $menuVisible={menuVisible}
          icon={<BiBuildingHouse />}
          key="/organization-details"
        >
          Organization
        </S.MenuItem>
        {user.subscription?.reportPermission && (
          <S.MenuItem $menuVisible={menuVisible} icon={<FiGrid />} key="/organization-dashboard">
            Dashboard
          </S.MenuItem>
        )}
        
      </S.Menu>
    ),
    [menuVisible, onSelectMenu, currentPath, user, isWideScreen],
  );
};
// Removed duplicate useOutsideClickHandler function

