import React from 'react';
import BookUserModal from '../BookUserModal';
import SubscriptionEndedModal from '../SubscriptionEndedModal';
import SubscriptionUpdateModal from '../SubscriptionUpdateModal';

const CommonModals = () => {
  return (
    <>
      <BookUserModal />
      <SubscriptionEndedModal />
      <SubscriptionUpdateModal />
    </>
  );
};

export default CommonModals;
