import styled from 'styled-components';

export const TransitionContainer = styled.div`
  min-width: 1200px;
  height: 600px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  background: #f0f0f0;
  @media (max-width: 1250px) {
    min-width: 1100px;
  }
  @media (max-height: 750px) {
    height: 500px;
    
  }
  @media (max-width: 1100px) {
    min-width: 800px;
  }
`;


export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Heading = styled.h1<{ $color: string }>`
  font-size: 2rem;
  font-weight: 500;
  margin-bottom: 1rem;
  text-align: center;
  color: ${(props) => props.$color || '#000'};
`;
export const ButtonImageWrapper = styled.div`
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
`;
export const Body = styled.p<{ $color: string }>`
  font-size: 0.8rem;
  text-align: center;
  line-height: 1.5;
  color: ${(props) => props.$color || '#000'};

  @media (max-width: 768px) {
    font-size: 0.9rem;
    max-width: 250px;
  }
`;

export const Button = styled.button<{ $foreground: string; $background: string }>`
  max-width: 280px;
  padding: 0.75rem 2rem;
  background: ${(props) => props.$background || '#4367e9'};
  color: ${(props) => props.$foreground || '#fff'};
  font-size: 1rem;
  font-weight: 600;
  border: none;
  border-radius: 25px;
  justify-content: center;
  cursor: pointer;
  transition: background 0.6s ease;
  will-change: background;
  margin-top: 1rem;

  &:hover {
    background: #e8edff;
  }
`;

export const TransitionPanel = styled.div<{ $background: string }>`
  width: 50%;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: ${(props) => props.$background || '#fff'};
  
  will-change: transform;
  transform: translateX(0);
  overflow: hidden;

  &.slide-left {
    transform: translateX(50%);
    transition: transform 0.45s ease-in;
  }
  &.slide-right {
    transform: translateX(-50%);
    transition: transform 0.45s ease-in;
  }
  &.center {
    transform: translateX(0);
    transition: transform 0.45s ease-out;
  }

  &.z1 {
    z-index: 1;
  }

  &.z0 {
    z-index: 0;
  }

  img {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-top: 1rem;
    padding-bottom: 2rem;
  }
`;

export const FormContainer = styled.form`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 0 auto;
`;

export const FormContainerH2 = styled.h2`
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  margin-bottom: 1.5rem;
  text-align: center;
`;

export const FormContainerInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.6s ease;

  &:focus {
    border-color: #4367e9;
    outline: none;
  }
`;
export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContainerButton = styled.button`
  width: 50%;
  padding: 0.75rem;
  background: #4367e9;
  color: white;
  border: none;
  border-radius: 40px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.6s ease;

  &:hover {
    background: #2b50c7;
  }
`;

export const SigninContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9rem;

  span {
    color: rgb(11, 12, 12);
    cursor: pointer;
  }
`;
