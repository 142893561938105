import styled from 'styled-components';

export const Logo = styled.img`
  height: 40px;
  cursor: pointer;
  
`;

export const Navbar = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 3rem;
`;

export const NavLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  gap: 60px;
`;

export const NavButton = styled.div`
  button {
    padding: 10px 20px;
    background-color: #4367E9;
    color: #fff;
    border: none;
    border-radius: 40px;
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

export const Link = styled.div`
  text-decoration: none;
  color: #000;
  font-weight: 500;

  &:hover {
    color: #007bff;
  }
`;