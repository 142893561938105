import styled from 'styled-components';


export const Container = styled.div`
  padding: 16px;
  display: flex;
 margin-top: 60px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const VideoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  min-width: 100%;
  gap: 16px;
  padding: 16px;
`;
export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
`;
export const VideoItem = styled.div`
  padding-top: 16px;
  border-radius: 8px;
  text-align: center;
  min-height: 100px;
  border: 1px solid #ccc;
`;
export const Icon = styled.div`
  align-self: flex-start;
  font-size: 32px;
  font-weight: 600;
  padding: 16px;
  cursor: pointer;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100%;
  padding: 16px;
  gap: 16px;
  button {
    border: 1px solid rgb(97, 96, 98);
    background-color: transparent;
    color: rgb(0, 0, 0);
    font-size: 16px;
    border-radius: 40px;
    padding: 8px 16px;
    cursor: pointer;

    &.active {
      border: 2px solid #007bff;
      color: #007bff;
    }
  }
`;
export const VideoText = styled.div`
  padding: 8px;
  font-size: 14px;
  color: #387B4B;
  background-color: #D8D6D6;
`;
