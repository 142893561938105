import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Form as AntdForm, Input, Button, message } from 'antd';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { gqlSchema } from '../../gql/schema';


const BookUserModal: React.FC = () => {
  const { user, isBookUser } = useAuth();
  const location = useLocation();
  const [form] = AntdForm.useForm();

  const bookModuleRoutesToExclude = [
    "/booklib",
    "/bookCode",
    "/bookDetails",
    "/bookPage",
    "/bookinvestigation",
    "/bookprofile",
    "/bookWishList",
    "/bookorderlist",
    "/bookorderdetail",
    "/bookquoteList",
    "/investigation-presentation",
  ];
  const [modalVisible, setModalVisble] = useState(false);

  // const shouldShowModal = bookModuleRoutesToExclude.includes(location.pathname) && !isBookUser;
  useEffect(() => {
    const isExcludedRoute = bookModuleRoutesToExclude.includes(location.pathname);
    setModalVisble(isExcludedRoute && !isBookUser);
  }, [location.pathname, isBookUser]);


  const [updateBookUser, { loading: updateLoading }] = useMutation(
    gqlSchema.BookSchema.mutations.UpdateBookUser,
    {
      onCompleted: () => {
        message.success("User metadata updated successfully!");
        setModalVisble(false);
        window.location.reload();
      },
      onError: (error) => {
        console.error(error);
        message.error("Failed to update user metadata.");
      },
    }
  );

  const userEmail = user?.email;

  const onSubmitBookUserForm = useCallback(
    async (values: Record<string, unknown>) => {
      try {
        await updateBookUser({
          variables: {
            metadata: JSON.stringify(values),
            email: userEmail,
          },
        });


      } catch (error) {
        message.error("There was an issue processing the metadata.");
      }
    },
    [updateBookUser, userEmail]
  );

  if (!modalVisible) return null;

  return (
    <Modal
  title={null}
  visible={modalVisible}
  footer={null}
  closable={false}
  width={600}
  zIndex={1100}
  bodyStyle={{
    padding: '40px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
  }}
>
  {/* Modal Header */}
  <div
    style={{
      textAlign: 'center',
      paddingBottom: '20px',
      borderBottom: '1px solid #e0e0e0',
    }}
  >
    <h1 style={{ fontSize: '22px', fontWeight: 600, color: '#333', margin: 0 }}>
      Update Book User
    </h1>
    <p style={{ fontSize: '14px', color: '#666', marginTop: '5px' }}>
      Door to ADI Library
    </p>
  </div>

  {/* Form Section */}
  <div style={{ padding: '20px' }}>
    <AntdForm
      form={form}
      onFinish={onSubmitBookUserForm}
      layout="vertical"
      initialValues={{
        jobDescription: '',
        state: '',
        city: '',
        country: '',
        zipCode: '',
      }}
    >
      <AntdForm.Item label="Job Description" name="jobDescription">
        <Input placeholder="Enter job description" style={{ borderRadius: '6px' }} />
      </AntdForm.Item>
      <AntdForm.Item label="State" name="state">
        <Input placeholder="Enter state" style={{ borderRadius: '6px' }} />
      </AntdForm.Item>
      <AntdForm.Item label="Country" name="country">
        <Input placeholder="Enter country" style={{ borderRadius: '6px' }} />
      </AntdForm.Item>
      <AntdForm.Item label="City" name="city">
        <Input placeholder="Enter city" style={{ borderRadius: '6px' }} />
      </AntdForm.Item>
      <AntdForm.Item label="Zip Code" name="zipCode">
        <Input placeholder="Enter zip code" type="number" style={{ borderRadius: '6px' }} />
      </AntdForm.Item>

      {/* Submit Button */}
      <AntdForm.Item style={{ marginTop: '25px', textAlign: 'center' }}>
        <Button
          type="primary"
          htmlType="submit"
          loading={updateLoading}
          style={{
            width: '80%',
            height: '40px',
            fontSize: '16px',
            fontWeight: 500,
            borderRadius: '8px',
            backgroundColor: '#4a6cf7',
            border: 'none',
          }}
        >
          Submit
        </Button>
      </AntdForm.Item>
    </AntdForm>
  </div>
</Modal>

  );
};

export default BookUserModal;
