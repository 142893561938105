import React, { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Select, Row, Col, Pagination, Typography, message ,Skeleton} from 'antd';
import * as S from './styles';
import Button from '../../../shared/Button';
import { useLazyQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import BookModal from './BookModle';
import LibrarySkeleton from './LibrarySkeleton';
import AddBookPopUp from './AddBookPopUp';
import { IoCloseCircle } from 'react-icons/io5';

const { Title } = Typography;
const { Option } = Select;

interface Book {
  id: string;
  title: string;
  grade: string;
  coverImage: string;
  description: string;
  investigations: number;
}

const MyLibrary: React.FC = () => {
  const bookcode = localStorage?.getItem('BookCode');

  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedGrade, setSelectedGrade] = useState<string | undefined>(undefined);
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>(undefined);
  const [firstRender, setFirstRender] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [addNewBookAddModalVisible, setAddNewBookModalVisible] = useState(bookcode ? true : false);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const subjectOptions = [
    { key: 'K', value: 'Grade K' },
    { key: '1', value: 'Grade 1' },
    { key: '2', value: 'Grade 2' },
    { key: '3', value: 'Grade 3' },
    { key: '4', value: 'Grade 4' },
    { key: '5', value: 'Grade 5' },
    { key: '6', value: 'Grade 6' },
    { key: '7', value: 'Grade 7' },
    { key: '8', value: 'Grade 8' },
    { key: 'K-2', value: 'Grade K-2' },
    { key: '3-5', value: 'Grade 3-5' },
    { key: '5-8', value: 'Grade 5-8' },
    { key: '9-12', value: 'Grade 9-12' },
  ];
  const [fetchBooks, { data, loading, error }] = useLazyQuery(gqlSchema.BookSchema.queries.FetchUserBooks, {
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });
  const closeAddNewBookPopUp = (refetch?: Boolean) => {
    setAddNewBookModalVisible(false);
    fetchBooks({
      variables: {
        page: currentPage,
        search: searchQuery,
        filter: { subject: selectedSubject, standard: selectedGrade },
      },
    });
  };
  useEffect(() => {
    if (data) {
      if (firstRender) {
        setFirstRender(false);
        if (data?.fetchUserBooks?.totalBooks === 0) {
          setAddNewBookModalVisible(true);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const query: { page: number; search: string; filter: { standard?: string; subject?: string } } = {
      page: currentPage,
      search: searchQuery,
      filter: {},
    };
    if (selectedGrade) {
      query.filter.standard = selectedGrade;
    }
    if (selectedSubject) {
      query.filter.subject = selectedSubject;
    }
    //@ts-ignore
    fetchBooks({ variables: query });

    // fetchBooks({
    //   variables: {
    //     page: currentPage,
    //     search: searchQuery,
    //     filter: { subject: selectedSubject, standard: selectedGrade},
    //   },
    // });
  }, [currentPage, fetchBooks, searchQuery, selectedGrade, selectedSubject]);

  // if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading books</p>;

  const booksData: Book[] = data?.fetchUserBooks?.bookResponse?.map((book: any) => ({
    id: book.id,
    title: book.title,
    grade: book.standard,
    coverImage: book.imageUrl[0] || '/path/to/default/image.jpg',
    description: book.description || 'No description available',
    investigations: book.investigations,
  }));
  console.log('BookData', booksData);
  const booksPerPage = 8;

  const openModal = (book: Book) => {
    setSelectedBook(book);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedBook(null);
  };

  return (
    <S.Container>
      <Button
        text="+ Add New Book"
        onClick={() => {
          setAddNewBookModalVisible(true);
        }}
        style={{ marginTop: 0, marginLeft: 'auto' }}
      />
      <Title level={2}>My Library</Title>

      <S.FilterRow>
        <S.SearchContainer>
          <SearchOutlined style={{ position: 'absolute', backgroundColor: 'transparent', marginLeft: 10 }} />
          <S.SearchInput type="search" placeholder="Search Book" onChange={(e) => setSearchQuery(e.target.value)} />
        </S.SearchContainer>

        <Select
          placeholder="Select Grade"
          value={selectedGrade || undefined}
          onChange={(value) => setSelectedGrade(value)}
          style={{ width: 150, marginLeft: '10px' }}
        >
          {subjectOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.value}
            </Option>
          ))}
        </Select>
        {selectedGrade && (
          <IoCloseCircle
            size={20}
            onClick={() => setSelectedGrade(undefined)}
            style={{ color: 'red', cursor: 'pointer' }}
          />
        )}

        <Select
          placeholder="Select Subject"
          value={selectedSubject || undefined}
          onChange={(value) => setSelectedSubject(value)}
          style={{ width: 150, marginLeft: '10px' }}
        >
          <Option value="SCIENCE">Science</Option>
          <Option value="MATHS">Maths</Option>
          <Option value="ENGINEERING">Engineering</Option>
          <Option value="CHEMISTRY">Chemistry</Option>
          <Option value="PHYSICS">Physics</Option>
          <Option value="BIOLOGY">Biology</Option>
        </Select>
        {selectedSubject && (
          <IoCloseCircle
            size={20}
            onClick={() => setSelectedSubject(undefined)}
            style={{ color: 'red', cursor: 'pointer' }}
          />
        )}
      </S.FilterRow>

      {loading ? <LibrarySkeleton row={2} /> : <CardSection booksData={booksData} openModal={openModal} />}

      <S.PaginationContainer>
        <Pagination
          current={currentPage}
          total={data?.fetchUserBooks?.totalBooks || 0}
          pageSize={booksPerPage}
          onChange={(page) => setCurrentPage(page)}
        />
      </S.PaginationContainer>

      {selectedBook && isModalVisible && (
        <BookModal isModalVisible={isModalVisible} closeModal={closeModal} selectedBook={selectedBook} />
      )}
      {addNewBookAddModalVisible && (
        <AddBookPopUp isModalVisible={addNewBookAddModalVisible} closeModal={() => closeAddNewBookPopUp()} />
      )}
    </S.Container>
  );
};

interface CardSectionProps {
  booksData: Book[];
  openModal: Function;
}
interface BookCardProps {
  book: Book;
  openModal: Function;
}
const CardSection: React.FC<CardSectionProps> = ({ booksData, openModal }) => {
  return (
    <Row gutter={[16, 16]}>
      {booksData === undefined ? (
        [...Array(8)].map((_, index) => (
          <Col key={index} xs={24} sm={12} md={6}>
            <Skeleton active avatar paragraph={{ rows: 2 }} />
          </Col>
        ))
      ) : booksData?.length === 0 ? (
        <S.NoDataMessage>No Books Added to Your Library.</S.NoDataMessage>
      ) : (
        booksData?.map((book) => <BookCard key={book.id} book={book} openModal={openModal} />)
      )}
    </Row>
  );
};

const BookCard: React.FC<BookCardProps> = ({ book, openModal }) => {
  return (
    <Col key={book.id} xs={24} sm={12} md={6}>
      <S.BookCard>
        <S.ImageWrapper>
          <img src={book.coverImage} alt={book.title} />
        </S.ImageWrapper>
        <S.BookTitle>{book.title}</S.BookTitle>
        <Button
          text="View"
          style={{ backgroundColor: '#4367E9', color: '#fff' }}
          onClick={() => openModal(book)}
          block
        />
      </S.BookCard>
    </Col>
  );
};
export default MyLibrary;
