/* eslint-disable max-lines */
import { gql } from '@apollo/client';
import INVESTIGATION_FRAGMENTS from '../fragment/Investigation';

const INVESTIGATION_QUERIES = {
  DASHBOARD: {
    getInvestigationsByUser: gql`
      query getInvestigationsByUser {
        getInvestigationsByUser {
          id
          title
          description
          completion
          dueDate
          engagementGrades
          feedbackGrades
          feedback
          classId
          grade
          scoreType
          investigationId
          isAssessment
          submissionVersion
          classStudent {
            id
            startDate
            endDate
            isLocked
          }
          discipline {
            ...DisciplineFragment
          }
          steps {
            completed
            activities {
              completed
              completedAt
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationsByTeacher: gql`
      query GetInvestigationsByTeacher($role: String) {
        getInvestigationsByTeacher(role: $role) {
          id
          investigationId
          completion
          title
          grade
          class
          classId
          classColorHex
          completion
          investigationId
          description
          dueDate
          isAssessment
          discipline {
            ...DisciplineFragment
          }
          submissionVersion
          students {
            id
            isLocked
            startDate
            endDate
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationsByTeacherWithoutGrade: gql`
      query GetInvestigationsByTeacher($role: String) {
        getInvestigationsByTeacher(role: $role) {
          id
          investigationId
          completion
          title
          class
          classId
          classColorHex
          completion
          investigationId
          description
          dueDate
          isAssessment
          discipline {
            ...DisciplineFragment
          }
          submissionVersion
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationSubmissionDownloadCache: gql`
      query getInvestigationSubmissionDownloadCache($classInvestigationId: String, $classId: String) {
        getInvestigationSubmissionDownloadCache(classInvestigationId: $classInvestigationId, classId: $classId) {
          id
          fileUrl
          status
        }
      }
    `,
    getInvestigationMaterialsDownloadCache: gql`
      query getInvestigationMaterialsDownloadCache($id: String) {
        getInvestigationMaterialsDownloadCache(id: $id) {
          id
          fileUrl
          status
        }
      }
    `,
    getInvestigationMaterialsDownloadCacheBooks: gql`
      query getInvestigationMaterialsDownloadCacheBooks($id: String) {
        getInvestigationMaterialsDownloadCacheBooks(id: $id) {
          id
          fileUrl
          status
        }
      }
    `,
  },
  
  COMMENT: {
    getComments: gql`
      query getCommentByActivityId($activityId: String!, $classId: String!) {
        getCommentByActivityId(activityId: $activityId, classId: $classId) {
          ...CommentFragment
          group
          responses {
            ...CommentFragment
            group
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
  },
  CLASS: {
    getInvestigationsByClassId: gql`
      query getInvestigationsByClassId($classId: String!) {
        getInvestigationsByClassId(classId: $classId) {
          id
          title
          classId
          investigationId
          isAssessment
          description
          dueDate
          grade
          completion
          discipline {
            ...DisciplineFragment
          }
          submissionVersion
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationsByClassIdWithoutGrade: gql`
      query getInvestigationsByClassId($classId: String!) {
        getInvestigationsByClassId(classId: $classId) {
          id
          title
          classId
          investigationId
          isAssessment
          description
          dueDate
          completion
          discipline {
            ...DisciplineFragment
          }
          submissionVersion
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getClassData: gql`
      query getClassData($data: QueryClassDataInput!) {
        getClassData(data: $data) {
          id
          firstName
          lastName
          email
          scores {
            title
            reportScore
            reportAverage
            engagementScore
          }
        }
      }
    `,
    getInvestigationSummary: gql`
      query getInvestigationProgressSummary($id: String!) {
        getInvestigationProgressSummary(id: $id) {
          id
          investigationId
          title
          classId
          class
          classColorHex
          letterHomeUrl
          standardsUrl
          completion
          description
          dueDate
          startDate
          onGoing
          teacherName
          avatar
          teacherEmail
          materials {
            stepname
            materials {
              ...MaterialFragment
            }
          }
          discipline {
            ...DisciplineFragment
          }
          perStudents {
            startDate
            dueDate
            userId
            firstName
            lastName
            avatar
            class
            group
            reportAverage
            reportScore
            maxReportScore
            feedbackGrade
            completion
            rating
            status
            steps {
              id
              order
              name
              dueDate
              completed
              status
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
    `,
    getAssessmentSummary: gql`
      query getInvestigationProgressSummary($id: String!) {
        getInvestigationProgressSummary(id: $id) {
          id
          investigationId
          title
          classId
          class
          classColorHex
          letterHomeUrl
          completion
          description
          dueDate
          startDate
          onGoing
          teacherName
          avatar
          teacherEmail
          resubmissionsLimit
          studentMaxSubmission
          studentMinCloseDate
          materials {
            stepname
            materials {
              ...MaterialFragment
            }
          }
          discipline {
            ...DisciplineFragment
          }
          crosscuttingConcepts {
            code
            text
          }
          parts {
            order
            name
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          standards {
            code
            text
          }
          format {
            id
            name
          }
          focus {
            id
            name
          }
          perStudents {
            userId
            firstName
            lastName
            avatar
            class
            group
            reportAverage
            reportScore
            maxReportScore
            feedbackGrade
            completion
            rating
            status
            startDate
            endDate
            isLocked
            isGraded
            tags {
              id
              tag
              color
            }
            steps {
              id
              order
              name
              dueDate
              completed
              status
              mode
              investigationMode
              activities {
                id
                content {
                  ...ContentFragment
                }
                contentInPerson {
                  ...ContentFragment
                }
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
    `,
  },
  CORE: {
    getInvestigationSettings: gql`
      query getInvestigationSettings($id: String!) {
        getInvestigationByIdForTeacher(id: $id) {
          id
          investigationId
          title
          startDate
          dueDate
          class
          classId
          classColorHex
          investigationId
          discipline {
            ...DisciplineFragment
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationById: gql`
      query getInvestigationById($id: String!) {
        getInvestigationById(id: $id) {
          id
          title
          dueDate
          classId
          investigationId
          description
          isAssessment
          isLocked
          type
          discipline {
            ...DisciplineFragment
          }
          steps {
            id
            name
            completed
            dueDate
            startDate
            mode
            requiresPreviousStep
            activities {
              id
              stepId
              name
              dueDate
              completed
              inPersonTeacher
              remoteTeacher
              inPersonTeacherTips
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
            }
          }
          classStudent {
            id
            endDate
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationByIdForTeacher: gql`
      query GetInvestigationByIdForTeacher($id: String, $classId: String, $investigationId: String) {
        getInvestigationByIdForTeacher(id: $id, classId: $classId, investigationId: $investigationId) {
          id
          isAssessment
          title
          classId
          investigationId
          startDate
          dueDate
          description
          discipline {
            ...DisciplineFragment
          }
          steps {
            id
            name
            completed
            startDate
            dueDate
            mode
            modesAvailable
            requiresPreviousStep
            activities {
              id
              stepId
              name
              completed
              inPersonTeacher
              inPersonTeacherTips
              remoteTeacher
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...TeacherContentFragment
              }
              contentInPerson {
                ...TeacherContentFragment
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.teacherContentFragment}
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationByIdByUserForTeacher: gql`
      query getInvestigationByIdByUserForTeacher($id: String, $userId: String) {
        getInvestigationByIdByUserForTeacher(id: $id, userId: $userId) {
          id
          title
          classId
          investigationId
          dueDate
          description
          discipline {
            ...DisciplineFragment
          }
          steps {
            id
            name
            completed
            dueDate
            mode
            activities {
              id
              stepId
              name
              completed
              inPersonTeacher
              remoteTeacher
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationCatalog: gql`
      query getInvestigationCatalog(
        $type: String
        $grade: String
        $investigationType: InvestigationType
        $isAssessment: Boolean
        $version: String
      ) {
        getInvestigationCatalog(
          type: $type
          grade: $grade
          investigationType: $investigationType
          isAssessment: $isAssessment
          version: $version
        ) {
          id
          title
          organization {
            id
            name
          }
          type
          description
          createdAt
          isDraft
          markedForDeletion
          firstPublishedAt
          availableForTrial
          kitUrl
          teacherFavorite
          discipline {
            ...DisciplineFragment
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          workshopElements {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          standards {
            id
            state
            code
            fullStatement
          }
          nextGenerationStandards {
            id
            state
            code
            fullStatement
          }
          steps {
            id
            mode
            activities {
              id
              nextGenerationStandards {
                id
                state
                code
                fullStatement
              }
            }
          }
          format {
            id
            name
          }
          focus {
            id
            name
          }
          time
        }
      }

      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationCatalogPaginated: gql`
      query getInvestigationCatalogPaginated(
        $subject: String
        $grade: String
        $investigationType: InvestigationType
        $isAssessment: Boolean
        $isTemplate: Boolean
        $version: String
        $page: Int
        $pageSize: Int
        $sortDirection: String
        $organizationId: String
        $discipline: String
        $status: String
        $teacherFavorite: String
        $trial: String
        $searchText: String
        $coreIdea: String
        $standard: String
        $mode: String
        $state: String
        $coreIdeas: String
        $firstPublishedAt: String
        $crossCuttingConcept: String
        $focus: String
        $practice: String
        $type: String
      ) {
        getInvestigationCatalogPaginated(
          subject: $subject
          grade: $grade
          investigationType: $investigationType
          isAssessment: $isAssessment
          isTemplate: $isTemplate
          version: $version
          page: $page
          pageSize: $pageSize
          sortDirection: $sortDirection
          organizationId: $organizationId
          discipline: $discipline
          teacherFavorite: $teacherFavorite
          trial: $trial
          status: $status
          searchText: $searchText
          coreIdea: $coreIdea
          standard: $standard
          mode: $mode
          state: $state
          coreIdeas: $coreIdeas
          firstPublishedAt: $firstPublishedAt
          crossCuttingConcept: $crossCuttingConcept
          focus: $focus
          practice: $practice
          type: $type
        ) {
          total
          page
          pageSize
          nextPage
          list {
            id
            title
            organization {
              id
              name
            }
            type
            description
            createdAt
            isDraft
            imageUrl
            markedForDeletion
            firstPublishedAt
            availableForTrial
            kitUrl
            teacherFavorite
            isTemplate
            isAssessment
            discipline {
              ...DisciplineFragment
            }
            coreIdeas {
              code
              text
            }
            practices {
              code
              text
            }
            workshopElements {
              code
              text
            }
            crosscuttingConcepts {
              code
              text
            }
            standards {
              id
              state
              code
              fullStatement
            }
            nextGenerationStandards {
              id
              state
              code
              fullStatement
            }
            steps {
              id
              mode
              activities {
                id
                nextGenerationStandards {
                  id
                  state
                  code
                  fullStatement
                }
                texasStandards {
                  id
                  state
                  code
                  fullStatement
                }
              }
            }
            format {
              id
              name
            }
            focus {
              id
              name
            }
            time
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationDrafts: gql`
      query getInvestigationDrafts($investigationType: InvestigationType, $isAssessment: Boolean) {
        getInvestigationDrafts(investigationType: $investigationType, isAssessment: $isAssessment) {
          id
          title
          organization {
            id
            name
          }
          description
          type
          isDraft
          createdAt
          markedForDeletion
          firstPublishedAt
          availableForTrial
          kitUrl
          teacherFavorite
          discipline {
            ...DisciplineFragment
          }
          workshopElements {
            code
            text
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          steps {
            id
            mode
          }
          format {
            id
            name
          }
          focus {
            id
            name
          }
          time
        }
      }

      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getInvestigationCatalogById: gql`
      query getInvestigationCatalogById($investigationId: String, $isAssessment: Boolean) {
        getInvestigationCatalogById(investigationId: $investigationId, isAssessment: $isAssessment) {
          id
          title
          description
          imageUrl
          explanation
          standardsUrl
          suppliesUrl
          kitUrl
          letterHomeUrl
          markedForDeletion
          type
          availableForTrial
          workshopElements {
            code
            text
          }
          discipline {
            ...DisciplineFragment
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          standards {
            id
            state
            code
            fullStatement
          }
          nextGenerationStandards {
            id
            state
            code
            fullStatement
          }
          steps {
            id
            name
            previewText
            inPersonPreviewText
            remotePreviewText
            order
            mode
            materials {
              ...MaterialFragment
            }
            activities {
              id
              stepId
              name
              order
              inPersonTeacher
              inPersonTeacherTips
              remoteTeacher
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
              nextGenerationStandards {
                id
                state
                code
                fullStatement
              }
            }
          }
          format {
            id
            name
          }
          focus {
            id
            name
          }
          time
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
    `,
    getPeerReviewByStepIdAndStudentId: gql`
      query getPeerReviewByStepIdAndStudentId($stepId: String, $studentId: String, $reviewerId: String) {
        getPeerReviewByStepIdAndStudentId(stepId: $stepId, studentId: $studentId, reviewerId: $reviewerId) {
          id
          stepId
          studentId
          userId
          fileUrl
          fileMIMEtype
          createdAt
          reflectionCompletedAt
          comments {
            ...CommentFragment
            coordinates {
              ts
            }
          }
          questionnaire {
            id
            feedback
            questionIndex
            answers {
              id
              answerIndex
              answer
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    getBestConceptPeerReview: gql`
      query getBestConceptPeerReview(
        $conceptActivityId: String!
        $conceptClassStepId: String!
        $conceptOwnerId: String!
        $reviewerId: String
      ) {
        getBestConceptPeerReview(
          conceptActivityId: $conceptActivityId
          conceptClassStepId: $conceptClassStepId
          conceptOwnerId: $conceptOwnerId
          reviewerId: $reviewerId
        ) {
          id
          createdAt
          completedAt
          reflectionCompletedAt
          rating
          reflection
          comments {
            ...CommentFragment
            coordinates {
              ts
            }
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.COMMENTS.commentFragment}
    `,
    getConcept: gql`
      query getConcept(
        $stepId: String!
        $evaluatedUserId: String!
        $activityId: String!
        $evaluatorId: String
        $conceptActivityId: String
      ) {
        getConcept(
          stepId: $stepId
          evaluatedUserId: $evaluatedUserId
          activityId: $activityId
          evaluatorId: $evaluatorId
          conceptActivityId: $conceptActivityId
        ) {
          files {
            fileUrl
            fileMIMEtype
          }
          materials {
            name
            amount
            price
          }
          question {
            title
            subquestions {
              text
            }
          }
          evaluation {
            ...EvaluationAnswerFragment
          }
        }
      }
      ${INVESTIGATION_FRAGMENTS.CONCEPT.evaluationAnswerFragment}
    `,
    getConceptFromPeerReviewId: gql`
      query getConceptFromPeerReviewId($peerReviewId: String!) {
        getConceptFromPeerReviewId(peerReviewId: $peerReviewId) {
          files {
            fileUrl
            fileMIMEtype
          }
          materials {
            name
            amount
            price
          }
        }
      }
    `,
    getConceptEvaluationsToYou: gql`
      query getConceptEvaluationsToYou($classStepId: String!, $activityId: String!) {
        getConceptEvaluationsToYou(classStepId: $classStepId, activityId: $activityId) {
          id
          completedAt
          evaluatorId
          evaluatedUserId
          conceptActivityId
        }
      }
    `,
  },
  CREATION: {
    getInvestigationDraftById: gql`
      query getInvestigationDraftById($id: String) {
        getInvestigationDraftById(id: $id) {
          id
          title
          description
          explanation
          isDraft
          imageUrl
          standardsUrl
          suppliesUrl
          letterHomeUrl
          markedForDeletion
          firstPublishedAt
          availableForTrial
          type
          kitUrl
          teacherNotesPdf
          materialUrlJson
          teacherFavorite
          isAssessment
          discipline {
            ...DisciplineFragment
          }
          coreIdeas {
            code
            text
          }
          workshopElements {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
          standards {
            id
            code
            grade
          }
          nextGenerationStandards {
            id
            code
            grade
          }
          steps {
            id
            name
            previewText
            inPersonPreviewText
            remotePreviewText
            order
            mode
            materials {
              ...MaterialFragment
            }
            activities {
              id
              name
              order
              inPersonTeacher
              inPersonTeacherTips
              remoteTeacher
              safety
              help
              helpVideoUrl
              teacherHelpVideoUrl
              content {
                ...ContentFragment
              }
              contentInPerson {
                ...ContentFragment
              }
              nextGenerationStandards {
                id
                code
                grade
                state
              }
              texasStandards {
                id
                code
                grade
                state
              }
            }
          }
          format {
            id
            name
          }
          focus {
            id
            name
          }
          time
        }
      }

      ${INVESTIGATION_FRAGMENTS.DISCIPLINE.disciplineFragment}
      ${INVESTIGATION_FRAGMENTS.MATERIAL.materialFragment}
      ${INVESTIGATION_FRAGMENTS.CONTENT.contentFragment}
    `,
    getInvestigationMetadataCodebook: gql`
      query getInvestigationMetadataCodebook {
        getInvestigationMetadataCodebook {
          disciplines {
            id
            name
            gradeBand
            subject
            coreIdeas {
              code
              text
            }
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          crosscuttingConcepts {
            code
            text
          }
        }
      }
    `,
    getWorkshopElements: gql`
      query getWorkshopElements {
        getWorkshopElements {
          code
          text
        }
      }
    `,
  },
  GRADING: {
    getFinalReportGrading: gql`
      query GetFinalReportGrading($investigationId: String!, $userId: String) {
        getFinalReportGrading(investigationId: $investigationId, userId: $userId) {
          grade
          scoreType
          questionnaire {
            index
            title
            questions {
              index
              question
              answer
            }
            feedback
          }
        }
      }
    `,
    getEngagementGrading: gql`
      query GetEngagementGrading($investigationId: String!, $userId: String) {
        getEngagementGrading(investigationId: $investigationId, userId: $userId) {
          grade
          questions {
            index
            question
            answer
          }
        }
      }
    `,
  },
  GRADE_BOOK: {
    getTeacherScores: gql`
      query getTeacherScores($data: TeacherScoresInput) {
        getTeacherScores(data: $data) {
          investigationId
          investigationTitle
          classId
          className
          reportScore
          reportAverage
          maxReportScore
          engagementScore
          engagementAverage
          maxEngagementScore
          gradedStudents
          gradeBand
          subject
          discipline
          startDate
        }
      }
    `,
    getAssessmentsComparisonById: gql`
      query getAssessmentsComparisonById($data: ComparisonByIdInput) {
        getAssessmentsComparisonById(data: $data) {
          typeTitle
          assessmentId
          submissionVersion
          baseInvestigationId
          items {
            index
            notSubmittedOrGraded
            below
            approaching
            meets
            aboveBarText
          }
        }
      }
    `,
    getAssessmentsComparisonByStandards: gql`
      query getAssessmentsComparisonByStandards($data: ComparisonByStandardsInput) {
        getAssessmentsComparisonByStandards(data: $data) {
          standardCode
          needsImprovement
          approaching
          meets
          notAvailable
        }
      }
    `,
    getTeacherAssessmentsScores: gql`
      query getTeacherAssessmentsScores($data: TeacherAssessmentScoresInput) {
        getTeacherAssessmentsScores(data: $data) {
          assessmentId
          assessmentTitle
          startDate
          gradedStudents
          unfinishedStudents
          assessmentScore
          maxAssessmentScore
          assessmentPercent
          performanceLevel
          baseInvestigationId
          startDate
          format {
            id
            name
          }
          focus {
            id
            name
          }
          discipline {
            name
            gradeBand
            subject
          }
          standards {
            id
            state
            code
            fullStatement
          }
          nextStandards {
            id
            state
            code
            fullStatement
          }
          parts {
            step
            order
            nextGenerationStandards {
              id
              state
              code
              fullStatement
            }
            texasStandards {
              id
              state
              code
              fullStatement
            }
          }
          coreIdeas {
            code
            text
          }
          practices {
            code
            text
          }
          graphData {
            notSubmittedOrGraded
            below
            approaching
            meets
          }
        }
      }
    `,
  },
  REASSIGNMENT: {
    getReassignmentData: gql`
      query getReassignmentData(
        $classId: String!
        $activityId: String!
        $studentId: String!
        $activityType: String!
        $notificationId: String!
        $submissionVersion: Int!
      ) {
        getReassignmentData(
          classId: $classId
          activityId: $activityId
          studentId: $studentId
          activityType: $activityType
          notificationId: $notificationId
          submissionVersion: $submissionVersion
        ) {
          peerReviewByYourselfType
          reviewInvestigationId
          class {
            id
            name
          }
          investigation {
            id
            classInvestigationId
            title
            description
          }
          step {
            id
            classStepId
            order
            name
          }
          activity {
            id
            order
            name
          }
          stuckUser {
            id
            email
            fullName
          }
          blockerUsers {
            id
            email
            fullName
          }
          newUsersToReassign {
            id
            email
            fullName
          }
          reassignedStudent {
            id
            email
            fullName
          }
        }
      }
    `,
  },
  REVIEW_SITE: {
    getLatestReviewSiteVersion: gql`
      query getLatestReviewSiteVersion {
        getLatestReviewSiteVersion
      }
    `,
  },
};

export default INVESTIGATION_QUERIES;
