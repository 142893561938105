import styled from 'styled-components';
import { Button } from 'antd';

export const Container = styled.div`
  padding: 24px;
  padding-left: 40px;
  padding-right: 40px;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const Title = styled.h1`
  font-size: 20px;
  font-weight: bold;
  word-wrap: break-word;
  white-space: pre-wrap;
`;

export const TagWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const Price = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: #767676;
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RequestButton = styled.button`
  margin: 0px;
  padding: 0px;
  outline: none;
  border: 1px solid #4367e9;
  color: #4367e9;
  padding: 4px;
  border-radius: 4px;
  font-weight: 600;
`;

export const AddToCart = styled.button`
  background-color: #007bff;
  color: white;
  padding: 4px 20px;
  border: none;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;



export const SideImages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height:23rem;
  overflow-x: auto;
  overflow-y: hidden;
  img {
    width: 76px;
    height: 76px;
    border-radius: 4px;
    object-fit: cover;
  }
  &.highlighted {
    border: 2px solidrgb(0, 0, 0);
  }
`;

export const MainImage = styled.img`
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  margin-left: 16px; 
  cursor: pointer;
  max-height: 30rem;
  object-fit: contain;
`;

export const TabContent = styled.div`
  font-size: 14px;
  color: #666;
  max-height: 200px;
  overflow-y: auto;
  white-space: pre-wrap;
`;

export const RecommendationsWrapper = styled.div`
  margin-top: 48px;
`;

export const RecommendationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h2 {
    font-size: 20px;
    font-weight: bold;
  }
`;

export const ArrowButton = styled(Button)`
  border: none;
  background: none;
  font-size: 18px;
  color: #3f51b5;
`;

export const BackButton = styled(Button)`
  color: black;
  padding: 0;
  font-size: 24px;
  border: none;
  background: none;
  margin-right: 20px;
`;