import { FaCheck } from 'react-icons/fa';
import React, { useCallback, useMemo } from 'react';
import * as S from './styles';
import logo from '../../assets/adi-learning-hub.svg';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { GQL_MeResponse } from '../../types/login';
import moment from 'moment';

const Modal: React.FC<{ children: React.ReactNode; onClose: () => void }> = ({ children, onClose }) => (
  <S.ModalOverlay>
    <S.ModalContent>
      <S.CloseButton onClick={onClose}>×</S.CloseButton>
      {children}
    </S.ModalContent>
  </S.ModalOverlay>
);


const getUserSubscription = (payload: { user?: GQL_MeResponse; activeRole?: string }) => {
  
  const { user, activeRole } = payload;
  const subscriptions = [...(user?.subscriptions || [])].sort((a, b) => b.endDate - a.endDate);

  if (activeRole === 'facilitator' || (!activeRole && user?.roles?.includes('facilitator'))) {
    const orgSubscription = subscriptions.find((subscription) => subscription.organizationId !== null);
    if (orgSubscription && user?.preferredSubscription === 'organization') {
      return orgSubscription;
    }
    return subscriptions.find((s) => s.type === 'facilitator');
  } else {
    const orgSubscription = subscriptions.find((subscription) => subscription.organizationId !== null);
    if (orgSubscription && user?.preferredSubscription === 'organization') {
      return orgSubscription;
    }
    return subscriptions.find((s) => s.type === 'standard');
  }
};
export const SubscriptionEndedModal = () => {
  const history = useHistory();
  const {
    user,
    signOut,
    isOrganizationAdiAdmin,
    isTeacherOrFacilitator,
    activeRole,
    isGoogleTeacher,
    isCanvasTeacher,
  } = useAuth();
  const userSubscription = getUserSubscription({ user, activeRole });
  const currentPath = window.location.pathname;
  const bookModuleRoutesToExclude = ['/booklib','/bookCode','/bookDetails','/bookPage','/bookinvestigation','/bookprofile','/bookWishList','/bookorderlist','/bookorderdetail','/bookquoteList','/investigation-presentation'];
  const isExcludedRoute = bookModuleRoutesToExclude.some(route => currentPath.startsWith(route));
  const endDate = useMemo(() => {
    const stripeSubscription = user?.stripeSubscription;

    if (stripeSubscription?.endDate && user?.preferredSubscription !== 'organization') {
      return moment(stripeSubscription?.endDate, 'x').toDate().valueOf();
    }

    return userSubscription?.endDate || null;
  }, [user, userSubscription]);
  const subscriptionExpired = (endDate && endDate < Date.now()) || false;
  const subscriptionNotStarted = (userSubscription?.startDate && userSubscription.startDate > Date.now()) || false;

  const subscriptionActive = !(subscriptionExpired || subscriptionNotStarted);
  const rolesWithSubscription = ['teacher', 'facilitator', 'organization_admin'];
  const showSubscriptionModal =
  !isExcludedRoute &&
    user &&
    (activeRole
      ? rolesWithSubscription.includes(activeRole)
      : isOrganizationAdiAdmin || isTeacherOrFacilitator || isGoogleTeacher || isCanvasTeacher) &&
    !subscriptionActive;
  const handleLogout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut]);
  // if (!isOpen) return null;

  if (!showSubscriptionModal) return null;

  return (
    <Modal onClose={()=>{}}>
      <S.Header>
        <img src={logo} alt="Adi Learning Hub Logo" onClick={() => history.push('/')} />
      </S.Header>
      <S.ContentContainer>
        <S.Title>YOUR SUBSCRIPTION HAS ENDED</S.Title>
        <S.BodyText>
          We no longer offer Pro and Premium subscription plans for individual teachers. Instead, you can purchase
          individual books and add them to your library using your existing account. Once a book is in your library,
          it’s yours forever.
        </S.BodyText>
        <S.FeatureList>
          <span>With each book, you will still have access to:</span>
          <S.FeatureItem>
            <FaCheck />
            <span>Ready-to-use student handouts (downloadable)</span>
          </S.FeatureItem>
          <S.FeatureItem>
            <FaCheck />
            <span>Presentation mode, which includes videos and color images for each investigation</span>
          </S.FeatureItem>
          <span>
            However, adding students to the Learning Hub is only available through an Organization Plan, which schools
            and districts can purchase.
          </span>
        </S.FeatureList>
        <S.FooterText>
          The next time you sign in, your account will be automatically updated and you will be able to start adding
          books to your library. If you have any questions, please feel free to reach out to our{' '}
          <a href="mailto:howdy@argumentdriveninquiry.com" style={{ color: '#4367E9', textDecoration: 'underline' }}>
            support team
          </a>
          .
        </S.FooterText>
        <S.ButtonContainer>
          <S.PrimaryButton onClick={() => history.push('/booklib')}>Switch to New Plan Now</S.PrimaryButton>
          <S.SecondaryButton onClick={handleLogout}>Sign Out</S.SecondaryButton>
        </S.ButtonContainer>
      </S.ContentContainer>
    </Modal>
  );
};

export default SubscriptionEndedModal;
