import React from 'react';
import * as S from './styles';
import { useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../gql/schema';
import { Empty, message } from 'antd';
import InvestigationCard from '../InvestigationCard';

const BookWishlistPage: React.FC = () => {
  const { data, refetch } = useQuery(gqlSchema.BookSchema.queries.FetchWishlist, {
    variables: {
      page: 1,
      limit: 10,
    },
    onError: (error) => {
      message.error(`Error in fetching Favorites details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const [removeFromWishlist] = useMutation(gqlSchema.BookSchema.mutations.RemoveWishlist, {
    onCompleted: () => {
      message.success('Investigation removing to Favorites');
      refetch();
    },
    onError: (error) => {
      message.error(`Error adding to Favorites- ${error.message || 'Unexpected Error'}`);
    },
  });
  const handleRemoveFromWishlist = (investigationId: string) => {
    removeFromWishlist({ variables: { investigationId } });
  };
  return (
    <S.PageContainer>
      <S.Header>
        <S.SearchInput placeholder="Search investigation" />
      </S.Header>
      {data?.getWishlist?.wishlist?.length === 0 && (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No investigation added to your favorites"
          style={{ alignSelf: 'center' }}
        />
      )}
      <S.CardGrid>
        {data?.getWishlist?.wishlist?.map((whishlist: any) => {
          return (
            <InvestigationCard
              key={whishlist.investigations.id}
              data={whishlist.investigations}
              cardType="wishlist"
              handleRemoveFromWishlist={() => handleRemoveFromWishlist(whishlist.investigationId)}
            />
          );
        })}
      </S.CardGrid>
    </S.PageContainer>
  );
};
export default BookWishlistPage;
