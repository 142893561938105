import styled from 'styled-components';
import { Row, Col, Modal as AntModal } from 'antd';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

export const Container = styled.div`
  padding: 20px;
  max-height: auto;
`;

export const FilterRow = styled(Row)`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  align-items: center;
`;

export const BookCard = styled(Col)`
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  img {
    width: 90%; 
    height: auto;
    border-radius: 4px; 
  }
`;

export const BookTitle = styled.h3`
  font-size: 14px; 
  margin-bottom: 8px; 
  margin-top:6px;
  font-weight: bold;''
  line-height: 1.4;
  height: 4.3em; 
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;
export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const Modal = styled(AntModal)`
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    background-color: #f0f2f5;
    border-radius: 8px 8px 0 0;
    border-bottom: none;
  }
  .ant-modal-title {
    font-size: 18px;
    font-weight: bold;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

export const PrevButton = styled.button`
  border-radius: 100%;
  border-color: #757575;
  color: #767676;
  background-color: white;
  border-width: 1px;
  border-style: solid;
  outline: none;
  padding-inline: 8px;
  padding-block: 2px;
`;

export const NextButton = styled.button`
  background-color: white;
  border-radius: 100%;
  border-color: #757575;
  color: #767676;
  border-width: 1px;
  border-style: solid;
  outline: none;
  padding-inline: 8px;
  padding-block: 2px;
`;
export const NoDataMessage = styled.p`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 24px;
  color: #666666;
  margin-left: 10px;
`;
