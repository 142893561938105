import React, { useCallback, useEffect, useState } from 'react';
import * as S from '../Signup/style';
import logo from '../../../../assets/adi-learning-hub.svg';
import cleverC from '../../../../assets/cleverC.png';
import googleClassroom from '../../../../assets/google-classroom.svg';
import classLinkLogoSrc from '../../../../assets/classlink.png';
import googleLogoSrc from '../../../../assets/google.png';
import canvas from '../../../../assets/canvas.png';
import { FaRegEnvelope } from 'react-icons/fa';
import { IoLockClosedOutline } from "react-icons/io5";
import {  useMutation, useQuery } from '@apollo/client';
import { gqlSchema } from '../../../../gql/schema';
import { GQL_LoginResponse, Organization } from '../../../../types/login';
import client from '../../../../gql/api';
import { message, Modal, Select, Typography, Tooltip, Spin } from 'antd';
import { useAuth } from '../../../../hooks/useAuth';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../../../shared/Button';
import useRoleUpdater from '../../../../hooks/useRoleUpdater'
import { LoadingOutlined } from '@ant-design/icons';

interface SignInProps {
  onActionBtnClick: (nextActivePanel: 'signin' | 'signup') => void;
  transitionState: string;
}

const SignIn: React.FC<SignInProps> = (props) => {
  const history = useHistory();
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const { setSignedIn, isTeacher, isStudent } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { Option } = Select;
  const { data: orgData, loading: loadingOrganizations, error: orgError } = useQuery(
    gqlSchema.CanvasSchema.queries.CANVASDASHBOARD.GetCanvasOrganizationList
  );
  
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const queryStringParams = searchParams.get('search') || '';
  const queryStringParamsObj = new URLSearchParams(queryStringParams);
  const code = queryStringParamsObj.get('code');
  const provider = queryStringParamsObj.get('provider');
  const handleChange = (key:string,value:string) => {
    setData({ ...data, [key]: value });
  };
  const organizationList = orgData?.getCanvasOrganizationList?.map((org: any) => org) || [];

  const handleLoginWithCanvas = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onLoginWithSSO = useCallback(({ provider }: { provider: 'clever' | 'classLink' | 'google' | 'canvas' | 'googleTest' }) => {
    const redirect_uri = window.location.origin;
    const classLinkClientId =
      process.env.REACT_APP_CLASSLINK_CLIENT_ID || 'c16318123360510b4e4a607247e29ecc6256f09d2b90';
    const googleClientId =
      process.env.REACT_APP_GOOGLE_CLIENT_ID ||
      '1004692858571-ur6ao926rvd9pkgcm6v2c1q9uff8mhud.apps.googleusercontent.com';

    const scope = `https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.profile.emails https://www.googleapis.com/auth/classroom.profile.photos https://www.googleapis.com/auth/classroom.coursework.students.readonly https://www.googleapis.com/auth/classroom.coursework.me.readonly https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/classroom.coursework.me https://www.googleapis.com/auth/userinfo.email`;

    let url = '';
    if (provider === 'classLink') {
      url = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirect_uri}/sso?provider=classlink&client_id=${classLinkClientId}&response_type=code`;
    } else if (provider === 'clever') {
      url = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=${redirect_uri}/sso?provider=clever&client_id=6fd9484b33eac85d533f`;
    } else if (provider === 'google') {
      url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${googleClientId}&response_type=code&scope=email&redirect_uri=${redirect_uri}/sso?provider=google&service=lso&o2v=2&flowName=GeneralOAuthFlow`;
    } else if (provider === 'canvas') {
      url = `${localStorage.getItem('CANVAS_API_DOMAIN')}/login/oauth2/auth?client_id=${localStorage.getItem('CANVAS_CLIENT_ID')}&response_type=code&redirect_uri=${redirect_uri}/sso?provider=canvas`;
    } else if (provider === 'googleTest') {
      url = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?client_id=${googleClientId}&response_type=code&scope=${scope}&redirect_uri=${redirect_uri}/sso?provider=googleTest&service=lso&o2v=2&flowName=GeneralOAuthFlow&access_type=offline&prompt=consent`;
    }
    const width = 600;
    const height = 800;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;
    const windowFeatures = `scrollbars=yes,resizable=yes,toolbar=no,menubar=no,location=no,directories=no,status=no,top=${top},left=${left},width=${width},height=${height}`;
    window.open(url, 'authPopup', windowFeatures);
  }, []);

  const handleOrganizationSelect = (value: string | undefined) => {
    const selectedOrg = organizationList.find((org: Organization) => org.id === value)
    localStorage.setItem('CANVAS_API_DOMAIN', selectedOrg.apiDomain)
    localStorage.setItem('CANVAS_ORG_ID', selectedOrg.organization.id)
    localStorage.setItem('CANVAS_CLIENT_ID', selectedOrg.clientId);
  };

  const handleProceed = () => {
    if (!localStorage.getItem('CANVAS_ORG_ID')) {
      alert("Please select an organization before proceeding.");
      return;
    }
    setIsModalVisible(false);
    onLoginWithSSO({ provider: "canvas" });
  };

  const { updatePreferredRoleToGoogleTeacher, updatePreferredRoleToGoogleStudent, updatePreferredRoleTeacher, updatePreferredRoleToCanvasStudent, updatePreferredRoleToCanvasTeacher } = useRoleUpdater();

  const [submitLoginWithClever, { loading: loadingCleverSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithClever,
    {
      onCompleted: ({ loginWithClever }: { loginWithClever: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithClever;
        setSignedIn({
          token: loginWithClever.accessToken,
          user: { ...data, id: loginWithClever.id },
        });
        client.resetStore();
        if (data.roles.includes('student')) history.push('/student-dashboard');
        else history.push('/teacher-dashboard');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithClassLink, { loading: loadingClassLinkSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithClasslink,
    {
      onCompleted: ({ loginWithClasslink }: { loginWithClasslink: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithClasslink;
        setSignedIn({
          token: loginWithClasslink.accessToken,
          user: { ...data, id: loginWithClasslink.id },
        });
        client.resetStore();
        if (data.roles.includes('student')) history.push('/student-dashboard');
        else history.push('/teacher-dashboard');
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithGoogle, { loading: loadingGoogleSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithGoogle,
    {
      onCompleted: ({ loginWithGoogle }: { loginWithGoogle: GQL_LoginResponse }) => {
        const { accessToken, ...data } = loginWithGoogle;
        setSignedIn({
          token: loginWithGoogle.accessToken,
          user: { ...data, id: loginWithGoogle.id },
        });
        client.resetStore();
        if (data.roles.includes('teacher')) {
          updatePreferredRoleTeacher()
        }
        else {
          history.push('/student-dashboard');
        }
      },
      onError: (error) => {
        message.error(error.message);
      },
    },
  );

  const [submitLoginWithGoogleTest, { loading: loadingGoogleSSOTest }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithGoogleTest,
    {
      onCompleted: ({ loginWithGoogleTest }: { loginWithGoogleTest: GQL_LoginResponse }) => {
        const { accessToken, googleAuthToken, googleAuthRefreshToken, ...data } = loginWithGoogleTest;
        setSignedIn({
          token: accessToken,
          user: { ...data, googleAuthToken, googleAuthRefreshToken },
        });
        if (loginWithGoogleTest.isGoogleTeacher) {
          updatePreferredRoleToGoogleTeacher();
          history.push('/googleclassroom-teacher-dashboard');
        }
        else {
          updatePreferredRoleToGoogleStudent();
          history.push('/googleclassroom-students-dashboard');
        }
        client.resetStore();
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  const [submitLoginWithCanvas, { loading: loadingCanvasSSO }] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.loginWithCanvas,
    {
      onCompleted: ({ loginWithCanvas }: { loginWithCanvas: GQL_LoginResponse }) => {
        const { accessToken, canvasAccessToken, canvasRefreshToken, ...data } = loginWithCanvas;
        setSignedIn({
          token: accessToken,
          user: { ...data, canvasAccessToken, canvasRefreshToken },
        });
        if (loginWithCanvas.isCanvasTeacher) {
          updatePreferredRoleToCanvasTeacher();
        }
        else {
          updatePreferredRoleToCanvasStudent();
        }
        client.resetStore();
      },
      onError: (error) => {
        message.error(error.message);
      },
    }
  );

  useEffect(() => {
    if (code) {
      if (provider === 'clever') {
        submitLoginWithClever({
          variables: {
            code,
          },
        });
      } else if (provider === 'googleTest') {
        submitLoginWithGoogleTest({
          variables: {
            code,
          },
        });
      } else if (provider === 'classlink') {
        submitLoginWithClassLink({
          variables: {
            code,
          },
        });
      } else if (provider === 'google') {
        submitLoginWithGoogle({
          variables: {
            code,
          },
        });
      } else if (provider === 'canvas' && localStorage.getItem('CANVAS_ORG_ID')) {
        submitLoginWithCanvas({
          variables: {
            code,
            canvasOrgId: localStorage.getItem('CANVAS_ORG_ID'),
          },
        });
      }
    }
  }, [code, provider, submitLoginWithClever, submitLoginWithClassLink, submitLoginWithGoogle, submitLoginWithGoogleTest, submitLoginWithCanvas]);

  const loadingSSO = loadingCleverSSO || loadingClassLinkSSO || loadingGoogleSSO || loadingGoogleSSOTest || loadingCanvasSSO;
  const isLoading = isLoggingIn || loadingSSO;

  const [submitLogin] = useMutation(
    gqlSchema.AccountsSchema.mutation.ACCOUNT.AUTH.login,
    {
      onCompleted: async ({ login }: { login: GQL_LoginResponse }) => {
        const { accessToken, ...data } = login;
        message.success("User Logged in successfully!");
        setSignedIn({
          token: accessToken,
          user: { ...data, id: login.id},
        });          
        client.resetStore();
        setIsLoggingIn(false);
        if (isTeacher){
          history.push('/teacher-dashboard')
        }
        else if (isStudent){
          history.push('/student-dashboard')
        }
        else history.push('/booklib')
      },
      onError: (error) => {
        message.error(
          <Typography.Text data-cy="components-loginpage-error-text">
            {error.message}
          </Typography.Text>
        );
        setIsLoggingIn(false);
      },
    }
  );

  const onSubmitForm = useCallback(
    (values) => {
      setIsLoggingIn(true);
      submitLogin({ variables: { data: values } });
    },
    [submitLogin]
  );

  return (
    <>
      <S.FormContainer className="form-container">
        {isLoading ? (
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: '100%',
            minHeight: '400px', 
          }}>
            <Spin 
              tip="Logging in..." 
              style={{ color: 'black' }}
              size="large" 
              indicator={<LoadingOutlined style={{ fontSize: '40px' , color: 'black' }} spin />}
            />
          </div>
        ) : (
          <>
            <S.ImageContainer>
              <img style={{ width: '40%' }} src={logo} alt="Logo" />
            </S.ImageContainer>
            <S.FormHeader>Sign in to the ADI Learning Hub</S.FormHeader>

            <S.InputGroup>
              <FaRegEnvelope />
              <S.Input 
                value={data.username} 
                onChange={(e) => handleChange("username", e.target.value)} 
                type="email" 
                placeholder="Email" 
              />
            </S.InputGroup>

            <S.InputGroup>
              <IoLockClosedOutline />
              <S.Input 
                value={data.password} 
                onChange={(e) => handleChange("password", e.target.value)} 
                type="password" 
                placeholder="Password" 
              />
            </S.InputGroup>

            <S.SigninContainer>
              <S.Button onClick={() => onSubmitForm(data)}>Sign In</S.Button>
              <span 
                onClick={() => history.push('/forgot-password')} 
                style={{
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }}>
                Forgot your password?
              </span>
            </S.SigninContainer>

            <S.LoginContainer>
              <span>Or sign in with</span>
              <S.ImageContainer>
                <Tooltip title={"Sign in with Google"}>
                  <img  
                    onClick={() => onLoginWithSSO({ provider: 'google' })} 
                    src={googleLogoSrc} 
                    alt="Google" 
                  />
                </Tooltip>
                <Tooltip title={"Sign in with Google Classroom"}>
                  <img 
                    onClick={() => onLoginWithSSO({ provider: 'googleTest' })} 
                    src={googleClassroom} 
                    alt="GoogleClassroom" 
                  />
                </Tooltip>
                <Tooltip title={"Sign in with Clever"}>
                  <img  
                    onClick={() => onLoginWithSSO({ provider: 'clever' })} 
                    src={cleverC} 
                    alt="Clever" 
                  />
                </Tooltip>
                <Tooltip title={"Sign in with ClassLink"}>
                  <img 
                    onClick={() => onLoginWithSSO({ provider: 'classLink' })} 
                    src={classLinkLogoSrc} 
                    alt="ClassLink" 
                  />
                </Tooltip>
                <Tooltip title={"Sign in with Canvas"}>
                  <img 
                    onClick={handleLoginWithCanvas} 
                    src={canvas} 
                    alt="Canvas" 
                  />
                </Tooltip>
              </S.ImageContainer>

              <div>
                <Button onClick={() => setIsModalVisible(true)}>
                  Select Organization
                </Button>
                <Modal
                  title="Select Canvas Organization"
                  visible={isModalVisible}
                  onCancel={handleCancel}
                  footer={null}
                  style={{ display: 'block' }}
                >
                  <div id="modal-content">
                    {loadingOrganizations ? (
                      <p>Loading organizations...</p>
                    ) : orgError ? (
                      <p>Error loading organizations: {orgError.message}</p>
                    ) : (
                      <>
                        {organizationList.length > 0 ? (
                          <Select
                            placeholder="Select an organization"
                            style={{ width: '100%', marginBottom: '1rem' }}
                            onChange={(value: string) => handleOrganizationSelect(value)}
                          >
                            {organizationList.map((organization: Organization) => (
                              <Option
                                key={organization.id}
                                value={organization.id}>
                                {organization.organization.name}
                              </Option>
                            ))}
                          </Select>
                        ) : (
                          <p>No organizations found.</p>
                        )}
                        <S.StyledButton
                          type="primary"
                          onClick={handleProceed}
                          block
                        >
                          Proceed
                        </S.StyledButton>
                      </>
                    )}
                  </div>
                </Modal>
              </div>
            </S.LoginContainer>
          </>
        )}
      </S.FormContainer>
    </>
  );
};

export default SignIn;