import { Col, Form as AntdForm, message, Row, Tabs, Tag, Button, Input, Modal, Skeleton, InputNumber } from 'antd';
import React, { useState, useEffect } from 'react';
import {
  ActionButtons,
  AddToCart,
  Container,
  MainImage,
  Price,
  RequestButton,
  SideImages,
  TabContent,
  TagWrapper,
  Title,
} from './BookPage.styles';

import { useMutation, useQuery } from '@apollo/client';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { gqlSchema } from '../../../gql/schema';
import { generatePdfAndUpload } from '../QuotePdf';
import BookRecommendation from './Recommendation/BookRecommendation';
import KitsRecommendation from './Recommendation/KitsRecommendation';
//import ShopifyBuyButton from './ShopifyBuyButton';

const { TabPane } = Tabs;

interface metadata {
  city: string;
  name: string;
  email: string;
  state: string;
  zipCode: string;
  street: string;
}

const BookPage: React.FC = () => {
  const history = useHistory();
  const { bookId } = useParams<{ bookId: string }>();
  const [loading, setLoading] = useState(false);
  // const [userCart, setUserCart] = useState<any>(null);
  const [userMetaData, setUserMetaData] = useState<metadata>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = AntdForm.useForm();

  const { refetch: refetchCart } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserCart, {
    onError: (error) => {
      message.error(`Error fetching cart data - ${error.message || 'Unexpected Error'}`);
    },
  });


  const { data: metadata } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getUserMetaData, {
    onCompleted: () => {
      setUserMetaData(metadata?.getUserMetaData); // Use the renamed variable
    },
    onError: (error) => {
      message.error(`Error fetching cart data - ${error.message || 'Unexpected Error'}`);
    },
  });

  const [addToCart] = useMutation(gqlSchema.ShopifySchema.mutations.ADD_TO_CART_SHOPIFY, {
    onCompleted: ({ data }) => {
      message.success('Product added to cart successfully!', data);
      refetchCart();
    },
    onError: (error) => {
      message.error(`Error adding product to cart - ${error.message || 'Unexpected Error'}`);
    },
  });


  const [sendQuoteEmailSingle] = useMutation<{ sendQuoteEmail: { pdfUrl: string, metadata: string, email: string, product: string, totalPrice: number, reference: string } }>(
    gqlSchema.ShopifySchema.mutations.SEND_QUOTE_EMAIL_SINGLE,
    {
      onCompleted: () => {
        message.success('Quote request sent successfully!');
        setTimeout(() => {
          const temp = false;
          temp && history.push('/bookquoteList');
        }, 5000);
      },
      onError: (error) => {
        message.error(`Error sending quote request - ${error.message || 'Unexpected Error'}`);
      },
    },
  );

  // useEffect(() => {
  //   if (cartData) {
  //     const cartItem =
  //       cartData.getUserCart?.cartItem?.map((item: any) => {
  //         return { ...item, unitPrice: parseFloat(item.unitPrice), quantity: parseInt(item.quantity) };
  //       }) || [];
  //     setUserCart({ ...cartData.getUserCart, cartItem });
  //   }
  // }, [cartData]);

  const { data } = useQuery(gqlSchema.BookSchema.queries.FetchBookById, {
    variables: {
      id: bookId,
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const { data: quoteData } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getQuoteData, {
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });

  const onSubmitForm = async (values: any) => {
    setIsModalVisible(false);
    await handleRequestQuote(values);
  };

  let quoteDetails = quoteData?.getQuoteData;
  const bookData = data?.fetchBookById;
  const [mainImage, setMainImage] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (bookData?.imageUrl) {
      setMainImage(bookData.imageUrl[0]);
    }
  }, [bookData]);
  const [saveQuoteToS3] = useMutation(gqlSchema.ShopifySchema.mutations.UPLOAD_QUOTE, {
    onCompleted: (data) => {
      console.log('File uploaded successfully:', data);
    },
    onError: (err) => {
      message.error('There was an error uploading your file: ' + err.message);
    },
  });

  const handleData = (quantity: number) => {
    const unitPrice = Number(bookData.price);
    const subtotal = unitPrice * quantity;
    const shipping = Number((subtotal / 10).toFixed(2));
    const total = subtotal + shipping;

    return {
      items: [
        {
          title: bookData.title,
          sku: bookData.barcode,
          quantity: quantity,
          unitPrice: unitPrice,
        }
      ],
      subtotal: subtotal,
      shipping: shipping,
      total: total,
    };
  };


  const handleRequestQuoteButtonClick = () => {
    setIsModalVisible(true);
  };

  // Handle form submission inside the modal
  const handleRequestQuote = async (formData: any) => {
    setLoading(true); // Show loading state

    try {
      if (!quoteDetails) {
        throw new Error('Quote details are not available.');
      }
      const quoteNumber = Math.random().toString(36).substring(2, 8).toUpperCase();
      console.log("QuoteNumber", quoteNumber);

      const metadata = `${formData.city || 'N/A'} 
            ${formData.state || 'N/A'} ${formData.country || 'N/A'} ${formData.zipcode || 'N/A'} `;
      quoteDetails = {
        ...quoteDetails,
        teacherName: formData?.name,
        email: formData?.email,
        shippingAddress: metadata,
        reference: quoteNumber,
      };

      const pdfBlob = await generatePdfAndUpload({ ...quoteDetails, ...handleData(formData.quantity) });

      if (!(pdfBlob instanceof Blob)) {
        throw new Error('Generated PDF is not a Blob.');
      }

      // const pdfUrl1 = URL.createObjectURL(pdfBlob);

      // // Trigger the download
      // const link1 = document.createElement('a');
      // link1.href = pdfUrl1;
      // link1.download = 'quote.pdf';
      // document.body.appendChild(link1);
      // link1.click();
      // document.body.removeChild(link1);

      // // Cleanup the URL
      // URL.revokeObjectURL(pdfUrl1);


      const file = new File([pdfBlob], 'quote.pdf', { type: 'application/pdf' });


      const link = await saveQuoteToS3({ variables: { file } });
      const pdfUrl = link?.data?.saveQuoteToS3;

      const productData = {
        title: bookData.product.title,
        price: parseFloat(bookData.price),
        barcode: bookData.product.barcode,
        quantity: formData.quantity,
        description: bookData.description

      }
      const total = parseFloat((parseFloat(bookData.price) * formData.quantity * 1.1).toFixed(2));
      await sendQuoteEmailSingle({ variables: { pdfUrl, metadata: JSON.stringify(formData), email: quoteDetails.email, product: JSON.stringify(productData), totalPrice: total, reference: quoteNumber } });
      message.success('Quote request sent successfully!');
      setIsModalVisible(false); // Close the modal after submission
    } catch (err) {
      message.error(`Error sending quote request: ${err || 'Unexpected Error'}`);
    } finally {
      setLoading(false);
    }
  };

  const addToCartCb = async () => {
    setLoading(true);
    try {
      await addToCart({
        variables: {
          variantId: [
            {
              merchandiseId: `gid://shopify/ProductVariant/${bookData.product.variantId}`,
              quantity: 1,
            },
          ],
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userMetaData) {
      form.setFieldsValue({
        name: userMetaData?.name || '',
        email: userMetaData?.email,
        street: userMetaData?.street || '',
        state: userMetaData?.state || '',
        city: userMetaData?.city || '',
        zipCode: userMetaData?.zipCode || '',
        quantity: 1,
      });
    }
  }, [userMetaData, form]);

  return (
    <Container>
      <FaArrowLeft onClick={() => history.push('/booklib')} size={16} />

      <Row gutter={24}>
        <Col span={10} style={{ display: 'flex', alignItems: 'flex-start' }}>
          <SideImages>
            {bookData?.imageUrl?.length === undefined ? (
              <>
                <Skeleton.Image style={{ width: 40, height: 40 }} />
                <Skeleton.Image style={{ width: 40, height: 40 }} />
                <Skeleton.Image style={{ width: 40, height: 40 }} />
              </>
            ) : (
              bookData?.imageUrl?.map((image: any, index: any) => (
                <img key={index} src={image} alt={`Side ${index + 1}`} onClick={() => setMainImage(image)} style={{ border: mainImage === image ? '2px solid blue' : 'none', cursor: 'pointer' }} />
              ))
            )}
          </SideImages>
          {bookData?.imageUrl?.length === undefined ? (
            <Skeleton.Image style={{ width: '400px', height: '400px' }} />
          ) : (
            <MainImage src={mainImage || ''} alt="Main Cover" />
          )}
        </Col>
        <Col span={14} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: '8px' }}>
          {bookData?.title === undefined ? (
            <Skeleton.Input style={{ width: '400px', height: '40px' }} />
          ) : (
            <Title>{bookData?.title}</Title>
          )}
          <TagWrapper>
            {bookData?.standard && <Tag
              color="#20BC89"
              style={{
                color: '#20BC89',
                fontWeight: 600,
                borderColor: '#20BC89',
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              {bookData?.standard} Grade
            </Tag>}
            <Tag
              color="#4367E9"
              style={{
                color: '#4367E9',
                fontWeight: 600,
                borderColor: '#4367E9',
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              {bookData?.investigations?.length || 0} Investigations
            </Tag>
          </TagWrapper>
          {bookData?.price === undefined ? (
            <Skeleton.Input style={{ width: '100px', height: '40px' }} />
          ) : (
            <Price>${bookData?.price}</Price>
          )}
          <ActionButtons>
            {bookData?.investigations?.length === undefined ? (
              <Skeleton.Button style={{ width: '100px', height: '40px', borderRadius: '20px' }} />
            ) : (
              <div>
                <AddToCart onClick={addToCartCb} disabled={loading}>
                  {loading ? 'Adding...' : 'Add to Cart'}
                </AddToCart>
              </div>
            )}
            {bookData?.investigations?.length === undefined ? (
              <Skeleton.Button style={{ width: '100px', height: '40px', borderRadius: '20px' }} />
            ) : (
              <RequestButton onClick={handleRequestQuoteButtonClick} disabled={loading}>
                Request A Quote
              </RequestButton>
            )}
          </ActionButtons>
          <div>
            <Modal
              title="Update Shipping Address"
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >
              <AntdForm form={form} onFinish={onSubmitForm} layout="vertical">
                <AntdForm.Item label="Name" name="name" rules={[{ required: true, message: 'Name is required' }]}>
                  <Input placeholder="Enter your name" type="text" />
                </AntdForm.Item>

                <AntdForm.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email address',
                    },
                  ]}
                >
                  <Input placeholder="Type your email here..." disabled />
                </AntdForm.Item>

                <AntdForm.Item label="Street Address" name="street">
                  <Input placeholder="Enter your Street Address" type="text" />
                </AntdForm.Item>

                <AntdForm.Item label="City" name="city">
                  <Input placeholder="Enter your city" type="text" />
                </AntdForm.Item>
                <AntdForm.Item label="State" name="state">
                  <Input placeholder="Enter your state" type="text" />
                </AntdForm.Item>

                {/* <AntdForm.Item label="Country" name="country">
                  <Input placeholder="Enter your country" type="text" />
                </AntdForm.Item> */}



                <AntdForm.Item label="Zip Code" name="zipCode">
                  <Input placeholder="Enter your zip code" type="number" />
                </AntdForm.Item>
                <AntdForm.Item
                  label="Quantity"
                  name="quantity"
                >
                  <InputNumber
                    min={1}
                    placeholder="Enter your quantity"
                    style={{ width: "100%" }}
                  />
                </AntdForm.Item>

                <AntdForm.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    block
                    style={{
                      width: '50%',
                      margin: '0.5em auto 0',
                      textAlign: 'center',
                      display: 'block',
                    }}
                  >
                    Submit
                  </Button>
                </AntdForm.Item>
              </AntdForm>
            </Modal>
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Description" key="1">
              {bookData?.description === undefined ? (
                <Skeleton.Input style={{ width: '400px', height: '200px' }} />
              ) : (
                <TabContent dangerouslySetInnerHTML={{ __html: bookData?.description || '' }} />
              )}
            </TabPane>
            <TabPane tab="Investigation List" key="2">
              <TabContent>
                {bookData?.investigations?.length === undefined ? (
                  <Skeleton.Input style={{ width: '400px', height: '200px' }} />
                ) : (
                  <ul>
                    {bookData?.investigations?.map((investigation: any, idx: number) => (
                      <li key={idx}>{`Investigation ${idx + 1}: ${investigation.title}`}</li>
                    ))}
                  </ul>
                )}
              </TabContent>
            </TabPane>
          </Tabs>
        </Col>
      </Row>

      <KitsRecommendation productId={bookId} />
      <BookRecommendation bookId={bookId} />
    </Container>
  );
};

export default BookPage;
