import styled from 'styled-components';
import { Button as antButton } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const FormContainer = styled.div`
  min-width: 100%;
  height: 500px;
  padding: 1rem;
      @media (max-height: 750px) {
    padding: 0.8rem;
    } 
`;
export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 19px;
  img {
    width: 30px;
  }
`;
export const NameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;
export const StyledButton = styled(antButton)`
  color: #000000;
  padding: 10px 20px;
  margin-bottom: 20px;
`;
export const InputGroup = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex: 1;
  svg {
    position: absolute;
    left: 6px;
    color: rgb(0, 0, 0);
    font-weight: 900;
    font-size: 1.3rem;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px 16px 12px 40px;
  border: none;
  font-size: 14px;
  background-color: rgba(108, 115, 128, 0.1);
  color: rgb(108, 115, 128);
  transition: border-color 0.2s;

  &:focus {
    outline: none;
    border-color: #667eea;
    box-shadow: 0 0 0 1px #667eea;
  }

  &::placeholder {
    color: rgb(3, 3, 3);
    font-weight: 500;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0;
  font-size: 14px;
  color: #4a5568;
  cursor: pointer;
  @media (max-height: 750px) {
    font-size:12px;
    }
  span {
    color: rgb(11, 12, 12);
    font-weight: 500;
  }
  a {
    color: rgb(11, 12, 12);
    text-decoration: underline;
    font-weight: 600;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Checkbox = styled.input`
  margin-right: 12px;
  width: 16px;
  height: 16px;
  accent-color: #4367e9;
  cursor: pointer;
`;
export const Button = styled.button`
  width: 45%;
  padding: 14px;
  background: #4367e9;
  color: white;
  align-items: center;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  transition: background 0.2s;
  @media (max-height: 750px) {
    font-size:14px; 
    padding:10px;
    letter-spacing:0.3px;
    }
  &:hover {
    background: #5a67d8;
  }

  &:active {
    background: #4c51bf;
  }
`;
export const SigninContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;
export const FormHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 32px;
  text-align: center;
  @media(max-width:1250px){
    font-size:22px;
  }
  @media (max-height: 750px) {
    font-size:20px;
    }
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  flex-direction: column;
  font-size: 0.7rem;
  font-weight: 600;

  img {
    width: 30px;
    object-fit: contain;
    mix-blend-mode: multiply;
    opacity: 0.9;
    cursor: pointer;
  }
`;
export const StyledLoadingIcon = styled(LoadingOutlined)`
  font-size: 40px;
`;
