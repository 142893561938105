import React, { useState } from 'react';
import { Modal } from 'antd';

import BookCode from './BookCode';
import BookDetails from './BookDetails';


interface BookModalProps {
  isModalVisible: boolean;
  closeModal: (refetch?:Boolean) => void;
}

const AddBookPopUp: React.FC<BookModalProps> = ({ isModalVisible, closeModal }) => {
  const userBookCode = localStorage?.getItem('BookCode');
  const [bookCode, setBookCode] = useState<string>(userBookCode?userBookCode:'');
  const handleAutoBookCode = (refetch?:Boolean)=>{
    if(localStorage){
      localStorage.removeItem('BookCode');
    }
    closeModal(refetch);
  }
  
  return (
    <Modal
      //title={userBookCode?'Your Book Awaits! Complete Setup':`Add New Book`}
      visible={isModalVisible}
      onCancel={()=>closeModal()}
      footer={null}
      width={1000}
      style={{justifyContent:'center', fontSize: '1.5rem'}}
      
    >
      {bookCode ?<BookDetails bookCode={bookCode} onClose={(refetch?:Boolean)=>handleAutoBookCode(refetch)}/>:<BookCode onSubmit={(val)=>setBookCode(val)}/>}
    </Modal>
  );
};

export default AddBookPopUp;