import React, { useEffect, useState } from "react";
import { Form as AntdForm, Button, Input, Modal, Tooltip } from "antd";
import * as S from './styles';
import { FaMinus, FaPlus, FaShoppingCart, FaTrash } from "react-icons/fa";
import { useMutation, useQuery } from "@apollo/client";
import { message } from "antd";
import { gqlSchema } from "../../../gql/schema";
import { useHistory } from "react-router-dom";
import { SyncOutlined } from '@ant-design/icons';
import { generatePdfAndUpload } from "../QuotePdf";


interface metadata {
    city: string;
    name: string;
    email: string;
    state: string;
    zipCode: string;
    street: string;
}
const CartPage: React.FC = () => {
    const [cartItems, setCartItems] = useState<any>([]);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [userCart, setUserCart] = useState<any>(null);
    const [userMetaData, setUserMetaData] = useState<metadata>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = AntdForm.useForm();
    const [totalPrice, setTotalPrice] = useState(0);
    const [checkoutLink, setCheckoutLink] = useState<string | null>(null);
    const [syncVisible, setSyncVisible] = useState(true)

    const { data: cartData, refetch: refetchCart } = useQuery(
        gqlSchema.ShopifySchema.queries.LIST.getUserCart,
        {
            onCompleted: (data) => {
                if (data?.getUserCart?.cartItem) {
                    setCartItems(data.getUserCart.cartItem);
                    setCheckoutLink(data.getUserCart.checkoutUrl);
                }
                if ((data?.getUserCart?.cartId && data?.getUserCart?.cartId?.includes('?key=')
                    && !(data?.getUserCart?.adiCart))) {
                    setSyncVisible(false);
                }
            },
            onError: (error) => {
                message.error(`Error fetching cart data - ${error.message || 'Unexpected Error'}`);
            },
        }
    );


    const [updateCartItem] = useMutation(
        gqlSchema.ShopifySchema.mutations.UPDATE_CART_ITEM_SHOPIFY,
        {
            onCompleted: () => {
                message.success('Cart item updated successfully!');
                refetchCart();
            },
            onError: (error) => {
                message.error(`Error updating cart item - ${error.message || 'Unexpected Error'}`);
            },
        }
    );

    const [deleteCartItem] = useMutation(
        gqlSchema.ShopifySchema.mutations.REMOVE_CART_ITEM_SHOPIFY,
        {
            onCompleted: () => {
                message.success('Cart item removed successfully!');
                refetchCart();
            },
            onError: (error) => {
                message.error(`Error removing cart item - ${error.message || 'Unexpected Error'}`);
            },
        }
    );

    const increaseQuantity = (item: any) => {
        if (item) {
            handleUpdateCartItem(item.product.shopifyProductId, item.cartLineId, item.quantity + 1);
            const updatedItems = cartItems.map((cartItem: any) =>
                cartItem.cartLineId === item.cartLineId
                    ? { ...cartItem, quantity: cartItem.quantity + 1 }
                    : cartItem
            );
            setCartItems(updatedItems);
        }
    };

    const decreaseQuantity = (item: any) => {
        if (item) {
            if (item.quantity > 1) {
                handleUpdateCartItem(item.product.shopifyProductId, item.cartLineId, item.quantity - 1);

                const updatedItems = cartItems.map((cartItem: any) =>
                    cartItem.cartLineId === item.cartLineId
                        ? { ...cartItem, quantity: cartItem.quantity - 1 }
                        : cartItem
                );
                setCartItems(updatedItems);
            } else {
                handleRemoveCartItem(item);
            }
        }
    };



    const handleUpdateCartItem = (shopifyProductId: string, cartLineId: string, quantity: number) => {

        if (shopifyProductId && quantity >= 0) {
            updateCartItem({
                variables: {
                    productId: shopifyProductId,
                    variantId: [
                        {
                            id: cartLineId,
                            quantity,
                        },
                    ],
                },
            });
        }
    };

    const handleRemoveCartItem = (item: any) => {
        setCartItems((prevCartItems: any) => prevCartItems.filter((cartItem: any) => cartItem.cartLineId !== item.cartLineId));
        deleteCartItem({
            variables: {
                productId: item.product.shopifyProductId,
                cartLineIds: [
                    item.cartLineId
                ]
            },
        });
    };

    useEffect(() => {
        const calculatedTotalPrice = cartItems.reduce((sum: number, item: any) => {
            return sum + (item.product?.price || 0) * (item.quantity || 0);
        }, 0);

        setTotalPrice(calculatedTotalPrice);
    }, [cartItems]);

    const { data: quoteData, refetch } = useQuery(gqlSchema.ShopifySchema.queries.LIST.getQuoteData, {
        onError: (error) => {
            message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
        },
    });
    const { data: metadata } = useQuery(
        gqlSchema.ShopifySchema.queries.LIST.getUserMetaData,
        {
            onCompleted: () => {
                setUserMetaData(metadata?.getUserMetaData); // Use the renamed variable
            },
            onError: (error) => {
                message.error(`Error fetching cart data - ${error.message || 'Unexpected Error'}`);
            },
        }
    );

    const [sendQuoteEmail] = useMutation<{ sendQuoteEmail: { pdfUrl: string, metadata: string, reference: string } }>(
        gqlSchema.ShopifySchema.mutations.SEND_QUOTE_EMAIL,
        {
            onCompleted: () => {
                message.success('Quote request sent successfully!');
                setTimeout(() => {
                    history.push('/bookquoteList');
                }, 5000);
            },
            onError: (error) => {
                message.error(`Error sending quote request - ${error.message || 'Unexpected Error'}`);
            },
        },
    );
    useEffect(() => {
        if (cartData) {

            const cartItem = cartData.getUserCart?.cartItem?.map((item: any) => {
                return { ...item, unitPrice: parseFloat(item.unitPrice), quantity: parseInt(item.quantity) };
            }) || [];
            setUserCart({ ...cartData.getUserCart, cartItem });
        }
    }, [cartData]);



    const onSubmitForm = async (values: any) => {
        setIsModalVisible(false);
        await handleRequestQuote(values);
    };



    let quoteDetails = quoteData?.getQuoteData;

    const [saveQuoteToS3] = useMutation(gqlSchema.ShopifySchema.mutations.UPLOAD_QUOTE, {
        onCompleted: (data) => {
            console.log('File uploaded successfully:', data);
        },
        onError: (err) => {
            message.error('There was an error uploading your file: ' + err.message);
        },
    });

    const handleRequestQuoteButtonClick = () => {
        setIsModalVisible(true);
    };

    // Handle form submission inside the modal
    const handleRequestQuote = async (formData: any) => {
        setLoading(true);  // Show loading state

        try {
            await refetch();

            if (!quoteDetails) {
                throw new Error('Quote details are not available.');
            }
            const quoteNumber = Math.random().toString(36).substring(2, 8).toUpperCase();

            const metadata = `${formData.city || "N/A"} 
                ${formData.state || "N/A"} ${formData.country || "N/A"} ${formData.zipcode || "N/A"} `
            quoteDetails = {
                ...quoteDetails,
                teacherName: formData?.name,
                email: formData?.email,
                shippingAddress: metadata,
                reference: quoteNumber

            }

            const pdfBlob = await generatePdfAndUpload({ ...quoteDetails, userCart });

            if (!(pdfBlob instanceof Blob)) {
                throw new Error('Generated PDF is not a Blob.');
            }
            // const pdfUrl1 = URL.createObjectURL(pdfBlob);

            // // Trigger the download
            // const link1 = document.createElement('a');
            // link1.href = pdfUrl1;
            // link1.download = 'quote.pdf';
            // document.body.appendChild(link1);
            // link1.click();
            // document.body.removeChild(link1);

            // // Cleanup the URL
            // URL.revokeObjectURL(pdfUrl1);


            const file = new File([pdfBlob], 'quote.pdf', { type: 'application/pdf' });


            const link = await saveQuoteToS3({ variables: { file } });
            const pdfUrl = link?.data?.saveQuoteToS3;

            await sendQuoteEmail({ variables: { pdfUrl, metadata: JSON.stringify(formData), reference: quoteNumber } });
            message.success('Quote request sent successfully!');
            setIsModalVisible(false);  // Close the modal after submission
        } catch (err) {
            message.error(`Error sending quote request: ${err || 'Unexpected Error'}`);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (userMetaData) {
            form.setFieldsValue({
                name: userMetaData?.name || '',
                email: userMetaData?.email,
                street: userMetaData?.street || '',
                state: userMetaData?.state || '',
                city: userMetaData?.city || '',
                zipCode: userMetaData?.zipCode || '',
            });
        }
    }, [userMetaData, form]);

    console.log("Quotee", cartData);


    return (
        <S.Container>
            <S.Header>
                <FaShoppingCart size={32} />
                <h1>Your Cart</h1>
                {syncVisible && <Tooltip title="Click here to sync your cart items with Shopify!">
                    {/* <FaInfoCircle
                        size={16}
                        onClick={() => window.open('https://shop.argumentdriveninquiry.com/', '_blank')}
                        style={{ marginLeft: 8, cursor: 'pointer' }}
                    /> */}
                    <SyncOutlined spin={false}
                        onClick={() => {
                            window.open('https://shopify.com/authentication/59877621933/login?client_id=c3c93cbe-63bb-4877-9d55-02324a3f8b20&locale=en&redirect_uri=https%3A%2F%2Fshopify.com%2Fauthentication%2F59877621933%2Foauth%2Fauthorize%3Fclient_id%3Dc3c93cbe-63bb-4877-9d55-02324a3f8b20%26locale%3Den%26nonce%3D49577baa-6650-4626-a49d-2b8500ab647f%26redirect_uri%3Dhttps%253A%252F%252Fshopify.com%252F59877621933%252Faccount%252Fcallback%253Fsource%253Dcore%26response_type%3Dcode%26scope%3Dopenid%2Bemail%2B', '_blank')
                            setTimeout(() => {
                                window.location.reload();
                            }, 15000);
                        }
                        }
                        style={{ fontSize: 24, color: '#1890ff' }} />
                </Tooltip>}
            </S.Header>


            <S.CartItemsContainer>
                {cartData?.getUserCart?.cartItem?.length > 0 ? (
                    cartData.getUserCart.cartItem.map((item: any) => (
                        <S.CartItemCard key={item.id}>
                            <S.ItemImage src={item.product.media[0]} alt={item.name} />
                            <S.ItemInfo>
                                <h3>{item.title}</h3>
                                <p>${item.product.price}</p>
                            </S.ItemInfo>

                            <S.QuantityControls>
                                <S.QuantityButton onClick={() => decreaseQuantity(item)} disabled={item.quantity === 1}>
                                    <FaMinus />
                                </S.QuantityButton>
                                <S.QuantityText>{item.quantity}</S.QuantityText>
                                <S.QuantityButton onClick={() => increaseQuantity(item)}>
                                    <FaPlus />
                                </S.QuantityButton>
                            </S.QuantityControls>

                            <S.DeleteButton onClick={() => handleRemoveCartItem(item)}>
                                <FaTrash size={20} />
                            </S.DeleteButton>
                        </S.CartItemCard>
                    ))
                ) : (
                    <S.EmptyCart>Your cart is empty.</S.EmptyCart>
                )}
            </S.CartItemsContainer>

            <S.TotalContainer>
                <h2>Total: ${totalPrice.toFixed(2)}</h2>
                <S.CheckoutButton
                    disabled={cartItems.length === 0}
                    onClick={() => {
                        if (checkoutLink) {
                            window.location.href = checkoutLink;
                        } else {
                            message.error("Checkout link is not available.");
                        }
                    }}
                >
                    Proceed to Checkout
                </S.CheckoutButton>
                <S.CheckoutButton onClick={handleRequestQuoteButtonClick} disabled={cartItems.length === 0}>Request a Quote</S.CheckoutButton>
                <div>
                    <Modal
                        title="Update Shipping Address"
                        visible={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={null}
                    >
                        <AntdForm
                            form={form}
                            onFinish={onSubmitForm}
                            layout="vertical"
                        >
                            <AntdForm.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Name is required' }]}
                            >
                                <Input placeholder="Enter your name" type="text" />
                            </AntdForm.Item>

                            <AntdForm.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please enter a valid email address',
                                    },
                                ]}
                            >
                                <Input placeholder="Type your email here..." disabled />
                            </AntdForm.Item>

                            <AntdForm.Item label="Street Address" name="street">
                                <Input placeholder="Enter your street address" type="text" />
                            </AntdForm.Item>

                            <AntdForm.Item label="City" name="city">
                                <Input placeholder="Enter your city" type="text" />
                            </AntdForm.Item>

                            <AntdForm.Item label="State" name="state">
                                <Input placeholder="Enter your state" type="text" />
                            </AntdForm.Item>

                            <AntdForm.Item label="Zip Code" name="zipCode">
                                <Input placeholder="Enter your zip code" type="number" />
                            </AntdForm.Item>

                            <AntdForm.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={loading}
                                    block
                                    style={{
                                        width: '50%',
                                        margin: '0.5em auto 0',
                                        textAlign: 'center',
                                        display: 'block',
                                    }}
                                >
                                    Submit
                                </Button>
                            </AntdForm.Item>
                        </AntdForm>

                    </Modal>
                </div>
            </S.TotalContainer>
        </S.Container>
    );
};



export default CartPage;
