import React, { useState } from 'react';
import * as S from './styles';
import { IoIosArrowRoundBack } from 'react-icons/io';

type VideoItemsType = {
  'Teaching Science with ADI': string[];
  'Teaching Math with ADI': string[];
  'Philosophy of ADI': string[];
  'Teaching with ADI': string[];
  'Kit Preparation Videos': string[];
  'Examples of ADI in the Classroom': string[];
};
const videoItems: VideoItemsType = {
  'Teaching Science with ADI': ['video1.mp4', 'video2.mp4', 'video3.mp4', 'video4.mp4', 'video5.mp4', 'video6.mp4'],
  'Teaching Math with ADI': ['video4.mp4', 'video5.mp4', 'video6.mp4', 'video8.mp4', 'video9.mp4'],
  'Philosophy of ADI': ['video7.mp4', 'video8.mp4', 'video9.mp4'],
  'Teaching with ADI': ['video10.mp4', 'video11.mp4', 'video8.mp4', 'video9.mp4', 'video12.mp4'],
  'Kit Preparation Videos': ['video13.mp4', 'video14.mp4', 'video15.mp4', 'video9.mp4'],
  'Examples of ADI in the Classroom': ['video16.mp4', 'video17.mp4', 'video18.mp4'],
};
const Videoarea = () => {
  const [activeTab, setActiveTab] = useState<keyof VideoItemsType>('Teaching Science with ADI');

  

  const handleTabClick = (key: keyof VideoItemsType) => {
    setActiveTab(key);
  };
  return (
    <S.Container>
      <S.Icon>
        <IoIosArrowRoundBack />
      </S.Icon>
      <S.ButtonWrapper>
        {Object.keys(videoItems).map((key, index:number) => (
          <button key={index} onClick={() => handleTabClick(key as keyof VideoItemsType)} className={activeTab === key ? 'active' : ''}>
            {key}
          </button>
        ))}
      </S.ButtonWrapper>
      <S.Title>{activeTab}</S.Title>
      <S.VideoGrid>
        {videoItems[activeTab]?.map((video, index) => (
          <S.VideoItem key={index}>
            <video width="320" height="240" controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <S.VideoText>ADI Stage 1 - Task - Overview</S.VideoText>
          </S.VideoItem>
        ))}
      </S.VideoGrid>
    </S.Container>
  );
};

export default Videoarea;
