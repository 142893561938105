import styled from 'styled-components';

export const PageContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;

  @media (max-width: 1435px) {
    padding: 1.5rem 0;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;

  @media (max-width: 1435px) {
    gap: 1.5rem;
  }
`;

export const Card = styled.div`
  background: white;
  border-radius: 1.5rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  min-width: 300px;
  max-height: 630px;
  text-align: center;

  @media (max-width: 1435px) {
    min-width: 280px;
    padding: 1.5rem;
  }
`;

export const CardContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
`;

export const Heading = styled.h1`
  font-size: 1.75rem;
  font-weight: 800;
  color: #1e293b;
  text-transform: uppercase;

  @media (max-width: 1435px) {
    font-size: 1.5rem;
  }
`;

export const SubHeading = styled.p`
  font-size: 1rem;
  color: rgba(57, 63, 71, 0.68);
  font-weight: 600;
  margin-bottom: 1.5rem;

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }
`;

export const FeatureList = styled.ul`
  text-align: left;
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 0;
    font-size: 0.8rem;
    max-width: 320px;
    color: rgba(75, 79, 85, 0.87);
    font-weight: 500;

    @media (max-width: 1435px) {
      font-size: 0.5rem;
      max-width: 180px;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 30px;
  border-radius: 50%;
  gap: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 30px;
    object-fit: contain;
  }

  @media (max-width: 1435px) {
    width: 18px;
    height: 24px;
  }
`;

export const Button = styled.button`
  background: #3b82f6;
  color: white;
  padding: 1rem 2rem;
  border-radius: 40px;
  border: 4px solid #3b82f6;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  width: fit-content;
  align-self: center;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-2px);
  }

  @media (max-width: 1435px) {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }
`;

export const IndividualWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
`;

export const OrgranizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  a{
    align-self: center;
  }
`;

export const Line = styled.div`
  height: 4px;
  width: 100%;
  background: #767676;
  margin: 1rem 0;
`;

export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #1e293b;

  span {
    font-size: 0.8rem;
  }

  @media (max-width: 1435px) {
    span {
      font-size: 0.75rem;
    }
  }
`;

export const Price = styled.div`
  font-size: 3rem;
  font-weight: 600;
  color: #3b82f6;
  margin-bottom: -0.7rem;

  @media (max-width: 1435px) {
    font-size: 2.5rem;
  }
`;
