import { gql } from '@apollo/client';
import ACCOUNT_FRAGMENTS from '../fragment/Accounts';

export const ACCOUNT = {
  AUTH: {
    passwordResetTokenCheck: gql`
      query PasswordResetTokenCheck($data: QueryPasswordResetTokenCheckInput!) {
        passwordResetTokenCheck(data: $data) {
          valid
        }
      }
    `,
  },
  PROFILE: {
    getUserAssigmentsLimits: gql`
      query getUserAssigmentsLimits {
        getUserAssigmentsLimits {
          totalAssignes {
            assessmentsAssigned
            investigationsAssigned
            classesAssigned
          }
          assignLimits {
            maxClasses
            maxAssessments
            maxInvestigations
          }
          assessmentsIds
          investigationsIds
        }
      }
    `,
    me: gql`
      query Me {
        me {
          id
          avatar
          emailVerified
          name
          username
          firstName
          active
          newWelcomeNote
          assistantsAllowed
          lastName
          roles
          userEnabled
          betaEnrolled
          tokenType
          enrollmentDate
          organizationName
          organizationAllowedStates {
            id
            name
            postalCode
          }
          userAllowedStates {
            id
            name
            postalCode
          }
          walkthroughs
          acknowledgedPresentationMessage
          isAllowedGrading
          isCleverAdmin
          isClasslinkAdmin
          isEnterprise
          isGoogleTeacher
          isGoogleStudent
          isCanvasStudent
          isCanvasTeacher
          isBookUser
          preferredRole
          preferredSubscription
          dismissedOnboarding
          dismissedInsightsTutorial
          subscription {
            ...Subscription
          }
          subscriptions {
            ...Subscription
          }
          emailPreference {
            id
            title
            active
          }
          source
          stripeSubscription {
            type
            endDate
            isDowngradePending
          }
          disabled
          suspended
          studentClassPermissions {
            classId
            immersiveReader
          }
        }
      }
      ${ACCOUNT_FRAGMENTS.subscription}
    `,
  },
  SIGN_UP: {
    getToken: gql`
      query GetToken($data: QueryInviteInput!) {
        invite(data: $data) {
          id
          email
          role
          firstName
          lastName
        }
      }
    `,
  },
};
