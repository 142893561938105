import React, { useState } from 'react';
import { Col, message, Row, Tabs, Tag } from 'antd';
import {
  Container,
  MainImage,
  TabContent,
  TagWrapper,
  Title,
} from './styles'

import { useMutation, useQuery } from '@apollo/client';
import { FaArrowLeft, FaCopy } from 'react-icons/fa';
import { useHistory, useParams } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import BookInvestigations from './BookInvestigations';
import BookKits from './BookKits';
import AddBookCodes from './AddBookCodes';
import * as S from './styles'
import { IoCloseCircle } from 'react-icons/io5';
const { TabPane } = Tabs;

const BookEdit: React.FC = () => {
  const history = useHistory();
  const { bookId } = useParams<{ bookId: string }>();
  const [selectedTab, setSelectedTab] = useState('1');
  const [searchQuery, setSearchQuery] = useState('');

  const { data, refetch } = useQuery(gqlSchema.BookSchema.queries.FetchBookById, {
    variables: {
      id: bookId,
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });
  const { data: bookCodes, refetch: refetchCodes } = useQuery(gqlSchema.BookSchema.queries.GetUnusedCodes, {
    variables: {
      bookId: bookId,
      limit: 1000
    },
    onError: (error) => {
      message.error(`Error in fetching book details - ${error.message || 'Unexpected Error'}`);
    },
  });
  const [assignInvestigationKitBundle] = useMutation(gqlSchema.BookSchema.mutations.AssignInvestigationKitBundle, {
    onCompleted: () => {
      message.success('Investigations Updated successfully');
      refetch()
    },
    onError: (error: any) => {
      message.error(`Error in assigning investigation kit bundle - ${error.message || 'Unexpected Error'}`);
    },
  });
  const onDelete = async (investigationId: string) => {
    try {
      const finalData = bookData?.investigations.map((i: any) => i.id).filter((id: string) => id !== investigationId);

      await assignInvestigationKitBundle({
        variables: {
          bookId,
          investigationIds: finalData,
        },
      });

    } catch (error) {
      console.error('Error assigning investigation kit bundle:', error);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      message.success('Code copied to clipboard');
    }).catch(err => {
      message.error('Failed to copy code');
    });
  };
  const bookData = data?.fetchBookById;
  const investigationsData = bookData?.investigations || [];
  const kitsData = bookData?.kit || [];
  const codeData = bookCodes?.getUnusedCodes || [];
  const filteredData = codeData.filter((code: any) =>
    code.code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <FaArrowLeft onClick={() => history.push('/bookadmin')} size={16} />
      <Row gutter={24}>
        <Col span={10} style={{ display: 'flex', alignItems: 'flex-start' }}>

          <MainImage
            src={
              bookData?.imageUrl?.[0]
            }
            alt="Main Book Cover"
          />
        </Col>
        <Col span={14} style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'column', gap: '8px' }}>
          <Title>{bookData?.title}</Title>
          <TagWrapper>

            <Tag
              color="#4367E9"
              style={{
                color: '#4367E9',
                fontWeight: 600,
                borderColor: '#4367E9',
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              {bookData?.investigations?.length || 0} Investigation
            </Tag>
          </TagWrapper>

          <div>

          </div>
          <Tabs onChange={(key) => setSelectedTab(key)} defaultActiveKey="1">

            <TabPane tab="Investigation List" key="1">
              <TabContent>
                <ul>
                  {investigationsData.map((investigation: any, idx: number) => {
                    return <li key={investigation.id}>{` ${idx + 1}: ${investigation.title}`} <IoCloseCircle onClick={() => onDelete(investigation.id)} size={20} style={{ cursor: 'pointer', top: '2px' }} /></li>
                  })}
                </ul>
              </TabContent>
            </TabPane>

            <TabPane tab="Related kits" key="2">
              <TabContent>
                <ul>
                  {
                    <li>-{` ${kitsData.title}`}</li>
                  }
                </ul>
                {(!kitsData) && <p>No kits available.</p>}
              </TabContent>

            </TabPane>
            <TabPane tab="Book Codes" key="3">
              <TabContent>
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  style={{
                    width: '40%',
                    padding: '8px',
                    marginBottom: '10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                />
                <ul>
                  {filteredData.map((code: any, idx: number) => (
                    <li key={code.id || idx}>{` ${idx + 1}: ${code.code}`} <FaCopy onClick={() => handleCopy(code.code)} style={{ cursor: 'pointer' }} /></li>

                  ))}
                </ul>
              </TabContent>
            </TabPane>

          </Tabs>
        </Col>
      </Row>
      <S.Divider />
      {
        selectedTab === '1' && <BookInvestigations selectedInvestigations={investigationsData} refetchBook={refetch} bookId={bookId} />
      }
      {
        selectedTab === '2' && <BookKits selectedKits={kitsData} refetchBook={refetch} bookId={bookId} />
      }
      {
        selectedTab === '3' && <AddBookCodes refetchBook={() => {
          refetchCodes({
            bookId: bookId,
            limit: 1000
          })
        }} shopifyProductId={bookData?.shopifyProductId} />
      }


    </Container>
  );

};


export default BookEdit;
