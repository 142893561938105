import React from 'react';
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';
import * as S from './style';

const LoginFooter= () => {
  const location = useLocation()
   const bottom = location.pathname === '/' ? '0' : 'auto'
  return (
    <S.Footer bottom={bottom}>
      <S.FooterLinks>
        <a href="https://adilearninghub.com/documents/terms-of-use.pdf" target="_blank" rel="noopener noreferrer">Terms of Use</a>
        <a href="https://adilearninghub.com/documents/privacy-policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        <a href="https://help.adilearninghub.com/knowledge" target="_blank" rel="noopener noreferrer">Help Center</a>
        <a href="mailto:howdy@argumentdriveninquiry.com">Contact Us</a>
      </S.FooterLinks>
      <S.CopyrightText>
        ©  {new Date().getFullYear()} Argument-Driven Inquiry, LLC
      </S.CopyrightText>
      <S.IconLinks>
        <a href="https://www.instagram.com/argumentdriveninquiry/"><FaInstagram style={{fontSize:"1.2rem"}} /></a>
        <a href="https://www.facebook.com/ArgumentDriven/"><FaFacebook style={{fontSize:"1.2rem"}} /></a>
        <a href="https://www.youtube.com/@ArgumentDriven/featured"><FaYoutube style={{fontSize:"1.5rem"}}  /></a>
        <a href="https://www.linkedin.com/company/argument-driven-inquiry/posts/?feedView=all"><FaLinkedin style={{fontSize:"1.2rem"}}  /></a>
      </S.IconLinks>
    </S.Footer>
  );     
};

export default LoginFooter;