/* eslint-disable complexity */
import { useLazyQuery } from '@apollo/client';
import { Layout, message } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import './App.css';
import { gqlSchema } from './gql/schema';
import { useAuth } from './hooks/useAuth';
import { themeConfig } from './utils/theme';
import { useLocation } from 'react-router-dom';
import Footer from './components/Books/Login_new/LoginFooter';
import MainHeader from './shared/MainHeader';
import CommonModals from './shared/CommonModals';

message.config({
  duration: 3,
  top: 80,
  maxCount: 1,
});
const App: React.FC = (props) => {
  const { isLogged, updateUser, isAdvancedSearch } = useAuth();
  const hasAuthenticatedHeader = isLogged && !isAdvancedSearch;
  const location = useLocation();

  const [fetchProfile] = useLazyQuery(gqlSchema.AccountsSchema.query.ACCOUNT.PROFILE.me, {
    onCompleted: (data) => {
      updateUser(data.me);
    },

    fetchPolicy: 'cache-and-network',
  });
  //#TODO : @Abhishek handle mobile condition appropreatly
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (hasAuthenticatedHeader) {
      fetchProfile();
    }
  }, [fetchProfile, hasAuthenticatedHeader]);

  const contentClass = useMemo(() => {
    if (hasAuthenticatedHeader || isAdvancedSearch) {
      return 'content-span';
    }
    return '';
  }, [hasAuthenticatedHeader, isAdvancedSearch]);

  const customStyle = ['/profile', '/videolib', '/home', '/test', '/learnmore', '/', '/account-type'].includes(
    location.pathname,
  );

  return (
    <ThemeProvider theme={themeConfig}>
      <Layout style={{ minHeight: '100vh', background: '#F5F5FB' }}>
        <MainHeader />

        <Layout.Content
          style={{
            flex: 1,
            padding: customStyle ? (isMobile ? '0 16px' : '0 24px') : '0px',
            marginTop: hasAuthenticatedHeader ? (isMobile ? '10px' : '64px') : '0',
            gap: '1rem',
          }}
        >
          <span role="none" className={contentClass}>
            {props.children}
          </span>
        </Layout.Content>

        <Layout.Footer style={{ textAlign: 'center', padding: '0' }}>
          <Footer />
        </Layout.Footer>
      </Layout>

      {/* IMP! :Add announcement modals in CommonModals only */}
      <CommonModals />
    </ThemeProvider>
  );
};

export default App;
