import React from 'react'
import Header from '../LandingHeader'
//import Footer from '../LandingFooter'
import FounderContent from './FounderContent'

const Profile = () => {
  return (
    <>
      <Header/>
      <FounderContent />

    </>
  )
}

export default Profile
