import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Avatar, Col, message, Modal, Row, Select, Skeleton, Spin, Tooltip } from 'antd';
import * as S from './styles';
import { FaLock, FaHeart } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { BiCollapse, BiCollapseAlt } from 'react-icons/bi';
import Button from '../../../shared/Button';
import { FiDownload } from 'react-icons/fi';
import { themeConfig } from '../../../utils/theme';
import { gqlSchema } from '../../../gql/schema';
import { useMutation, useQuery } from '@apollo/client';
//import InvestigationCardSkeleton from './InvestigationCardSkeleton';
import ShopifyWrapper from './ShopifyWrapper';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';

interface InvestigationCardProps {
  data: any;
  isActive?: boolean;
  setFullScreen?: () => void;
  closeFullScreen?: () => void;
  addToWishlist?: () => void;
  handleRemoveFromWishlist: () => void;
  isFavorite?: boolean;
  cardType?: string;
}
const InvestigationCard: React.FC<InvestigationCardProps> = ({
  data,
  isActive,
  setFullScreen,
  closeFullScreen,
  addToWishlist,
  handleRemoveFromWishlist,
  isFavorite,
  cardType,
}) => {
  const [currentTab, setCurrentTab] = useState('1');
  const [investigationId, setInvestigationId] = useState();
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (data?.id) {
      setInvestigationId(data.id);
      setLoading(false);
    }
  }, [data]);

  if (loading) {
    return <Skeleton active avatar paragraph={{ rows: 4 }} />;
  }
  const handleIconClick = () => {
    if (isActive) {
      if (closeFullScreen) {
        closeFullScreen();
      }
    } else {
      if (setFullScreen) {
        setFullScreen();
      }
    }
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case '1':
        return <InvestigationTab data={data} />;
      case '2':
        return <StandardTab investigationId={investigationId} />;
      case '3':
        return <MaterialTab investigationId={investigationId} kitUrl={data.kitUrl} />;
      default:
        return null;
    }
  };
  return (
    <S.CardContainer className={`${isActive ? 'fullview' : ''} `}>
      <S.StyledTabs onChange={(value) => setCurrentTab(value)} defaultActiveKey={currentTab}>
        <S.TabPane tab="Investigation" key="1" />
        <S.TabPane tab="Standard" key="2" />
        <S.TabPane tab="Material" key="3" />
        <S.TabPane
          disabled
          tab={
            <Tooltip title="This feature is only available for organization accounts" placement="bottom">
              <span>
                Teacher Notes <FaLock />
              </span>
            </Tooltip>
          }
          key="4"
        />
      </S.StyledTabs>

      <S.Icons>
        {cardType === 'wishlist' ? (
          <S.Icon onClick={() => handleRemoveFromWishlist()}>
            <RiDeleteBin5Line size={18} color="red" />
          </S.Icon>
        ) : (
          <>
            <S.Icon
              onClick={() => {
                handleIconClick();
              }}
            >
              {isActive ? <BiCollapseAlt /> : <BiCollapse />}
            </S.Icon>
            <S.Icon>
              <FaHeart
                color={isFavorite ? 'red' : ''}
                onClick={() => (isFavorite ? handleRemoveFromWishlist() : addToWishlist?.())}
              />
            </S.Icon>
          </>
        )}
      </S.Icons>

      {renderTabContent()}
    </S.CardContainer>
  );
};

interface InvestigationTabProps {
  data: any;
}

interface InvestigationIdProps {
  investigationId: any;
  kitUrl?: string;
}
const downloadFile = (url: string, filename: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
const InvestigationTab: React.FC<InvestigationTabProps> = ({ data }) => {
  const history = useHistory();
  const [openMaterials, setOpenMaterials] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const [getAllMaterials] = useMutation(
    gqlSchema.InvestigationSchema.mutations.SETTINGS.getAllInvestigationMaterialsForBooks,
    {
      onCompleted: (data) => {
        setDownloading(false);
        downloadFile(data.getAllInvestigationMaterialsForBooks.fileUrl, 'Investigation-allMaterials.zip');
      },
      onError: (error) => {
        setDownloading(false);
        message.error(
          error.message ||
          'There was an error downloading all materials, please check your connection and try again later',
        );
      },
      fetchPolicy: 'network-only',
    },
  );

  const { data: initialCacheQuery, startPolling, stopPolling } = useQuery(
    gqlSchema.InvestigationSchema.queries.DASHBOARD.getInvestigationMaterialsDownloadCacheBooks,
    {
      variables: { id: data.id },
    },
  );

  const initialCacheStatus = initialCacheQuery?.getInvestigationMaterialsDownloadCache;
  const cacheUrl = initialCacheStatus?.fileUrl;

  const downloadAllMaterials = useCallback(() => {
    setDownloading(true);

    if (!cacheUrl) {
      startPolling(5000);
      getAllMaterials({ variables: { id: data.id } });
      setIsPolling(true);
    } else {
      setTimeout(() => {
        setDownloading(false);
        window.location.href = cacheUrl;
      }, 3000);
    }
  }, [data.id, cacheUrl, startPolling, getAllMaterials]);

  useEffect(() => {
    if (isPolling && cacheUrl) {
      setIsPolling(false);
      stopPolling();
      setDownloading(false);
      window.location.href = cacheUrl;
    }
  }, [cacheUrl, isPolling, stopPolling]);

  return (
    <>
      <S.GradeText>{data.grade}</S.GradeText>
      <Avatar
        src={
          data.imageUrl || 'https://cdn.shopify.com/s/files/1/0598/7762/1933/files/7th_grade_texas-1.png?v=1731777175'
        }
        size={105}
      />
      <S.Title>{data.title}</S.Title>
      <S.Description dangerouslySetInnerHTML={{ __html: data.description || '' }} />

      <div className="action-btn-container">
        <button onClick={() => setOpenMaterials(true)} className="active">
          Handouts
        </button>
        <button
          onClick={() =>
            data?.id
              ? history.push(`/investigation-presentation/${data.id}?source=book`, {
                backUrl: history.location.pathname,
              })
              : null
          }
          className="active"
        >
          Presentation Mode
        </button>
      </div>


      <Modal visible={openMaterials} onCancel={() => setOpenMaterials(false)} footer={null} width={700}>
        <S.MaterialsModalContainer>
          <h1>Investigation Downloads</h1>
          <Spin spinning={downloading} size="large">
            <Row>
              <Col span={12} offset={6}>
                Here you can find the downloadable materials for each stage
              </Col>
              <Col span={6}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '4rem' }}>
                  <Button
                    style={{ marginLeft: '-30.5rem' }}
                    text="Download Material"
                    theme={themeConfig.primaryColor}
                    icon={<FiDownload />}
                    onClick={downloadAllMaterials}
                    disabled={downloading}
                    loading={downloading}
                  />
                </div>
              </Col>
            </Row>
          </Spin>
        </S.MaterialsModalContainer>
      </Modal>

      <div className="action-btn-container">
        <Tooltip title="This feature is only available for organization accounts" placement="bottom">
          <button>
            Remote Preview <FaLock />
          </button>
        </Tooltip>
        <Tooltip title="This feature is only available for organization accounts" placement="bottom">
          <button>
            Assign To Class <FaLock />
          </button>
        </Tooltip>
      </div>
    </>
  );
};

const { Option } = Select;

type Standard = {
  Code: string;
  subject: string;
  description: string;
};

type StandardsData = {
  [key: string]: Standard;
};

const StandardTab: React.FC<InvestigationIdProps> = ({ investigationId }) => {
  const states = [
    'Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California',
    'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Florida',
    'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas',
    'Kentucky', 'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan',
    'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada',
    'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina',
    'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island',
    'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
    'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
  ];

  const standards = [
    { value: 'maths', label: 'Maths' },
    { value: 'science', label: 'Science' },
    { value: 'engineering', label: 'Engineering' },
    { value: 'ela-reading', label: 'ELA-reading' },
    { value: 'ela-writing', label: 'ELA-Writing' },
    { value: 'ela-speaking-and-listening', label: 'ELA-speaking and listening' },
    { value: 'language-development', label: 'Language development' },
  ];

  const [selectedStandards, setSelectedStandards] = useState<string[]>([]);
  const [selectedState, setSelectedState] = useState<string>('');
  const [altStandardData, setAltStandardData] = useState<StandardsData>({});

  const [addAltStandards, { loading }] = useMutation(
    gqlSchema.BookSchema.mutations.AddAltStandards,
    {
      onCompleted: (data) => {
        try {
          const parsedData = JSON.parse(data.addAllStatesAndStandards.altStandard);
          const tempData = parsedData[0];
          setAltStandardData(tempData);
          message.success('Standards applied successfully!');
        } catch (error) {
          message.error('Failed to load standards data.');
        }
      },
      onError: (error) => {
        message.error(`Error: ${error.message}`);
      },
    }
  );

  const handleStandardChange = (selectedItems: string[]) => {
    setSelectedStandards(selectedItems);
  };

  const handleStandardSubmit = () => {
    if (!selectedState || selectedStandards.length === 0) {
      message.warning('Please select a state and standards before submitting.');
      return;
    }

    addAltStandards({
      variables: {
        investigationId,
        state: selectedState,
        standard: selectedStandards,
      },
    });
  };

  const styles = StyleSheet.create({
    page: {
      padding: 30,
      fontFamily: 'Helvetica',
      fontSize: 10,
    },
    header: {
      fontSize: 18,
      fontWeight: 'bold',
      marginBottom: 20,
      textAlign: 'center',
    },
    tableRow: {
      flexDirection: 'row',
      borderBottom: '1px solid #ddd',
    },
    tableCell: {
      width: '33.33%',
      padding: 8,
      fontSize: 10,
      border: '1px solid #ddd',
    },
  });

  const generatePDFDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <Text style={styles.header}>Alt Standards Report</Text>
        <Text>State: {selectedState}</Text>
        <Text>Standards: {selectedStandards.join(', ')}</Text>
        <View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Subject</Text>
            <Text style={styles.tableCell}>Code</Text>
            <Text style={styles.tableCell}>Description</Text>
          </View>
          {Object.entries(altStandardData).map(([subject, value]) => (
            <View key={subject} style={styles.tableRow}>
              <Text style={styles.tableCell}>{value.subject}</Text>
              <Text style={styles.tableCell}>{value.Code}</Text>
              <Text style={styles.tableCell}>{value.description}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  return (
    <S.StandardTabContainer>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <Select
          placeholder="Select State"
          style={{ width: '50%' }}
          onChange={setSelectedState}
          value={selectedState || undefined}
        >
          {states.map((state) => (
            <Option key={state} value={state}>
              {state}
            </Option>
          ))}
        </Select>
        <Select
          mode="multiple"
          placeholder="Select Standards"
          style={{ width: '300px' }}
          onChange={handleStandardChange}
          value={selectedStandards}
        >
          {standards.map((standard) => (
            <Option key={standard.value} value={standard.value}>
              {standard.label}
            </Option>
          ))}
        </Select>
        <S.ActionButton onClick={handleStandardSubmit} loading={loading}>
          View Standards
        </S.ActionButton>
      </div>

      {/* Scrollable List Container */}
      <div
        style={{
          marginTop: '20px',
          maxHeight: '300px',
          overflowY: 'auto', // Enable scrolling
          border: '1px solid #ddd',
          padding: '10px',
        }}
      >
        {Object.keys(altStandardData).length > 0 ? (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Subject</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Code</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Description</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(altStandardData).map(([subject, value]) => (
                <tr key={subject}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{value.subject}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{value.Code}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{value.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No standards available. Select state and standards to fetch.</p>
        )}
      </div>

      {/* PDF Download Link */}
      <div className="action-btn-container">
        <PDFDownloadLink
          document={generatePDFDocument()}
          fileName={`alt_standards_report_${selectedState}.pdf`}
        >
          {({ loading }) =>
            loading ? <button>Loading PDF...</button> : <button>Download PDF</button>
          }
        </PDFDownloadLink>
      </div>
    </S.StandardTabContainer>
  );
};




const MaterialTab: React.FC<InvestigationIdProps> = ({ investigationId, kitUrl }) => {
  const [isOverlayVisible, setOverlayVisible] = useState(false);

  const toggleOverlay = () => setOverlayVisible(!isOverlayVisible);
  const { data, loading, error } = useQuery(gqlSchema.BookSchema.queries.getInvestigationMaterial, {
    variables: { investigationId },
    fetchPolicy: 'network-only',
    onError: () => {
    },
  });

  const materialData = data?.getInvestigationMaterial;
  const handle = useMemo(() => {
    if (kitUrl) {
      try {
        return kitUrl.split('?')[0].split('/').pop();
      } catch (e) {
        return '';
      }
    }
    return '';
  }, [materialData, kitUrl]);
  const { data: product } = useQuery(gqlSchema.ShopifySchema.queries.LIST.GetShopifyProductByHandle, {
    variables: { handle: handle },
    skip: !handle,
  });
  const shopifyId = product?.getShopifyProductByHandle?.variantId;

  return (
    <div>
      <S.TableContainer style={{ position: 'relative' }}>
        {loading ? (
          <S.LoadingMessage>Loading material...</S.LoadingMessage>
        ) : error ? (
          <S.ErrorBlock>
            <S.ErrorMessage>No Material or Simulation to show.</S.ErrorMessage>
          </S.ErrorBlock>
        ) : materialData ? (
          materialData.materialLink && materialData.simulationLink ? (
            <>
              <S.ScrollableBlock key={`${investigationId}-material`}>
                <img
                  src={materialData.materialLink}
                  alt="Material Image"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </S.ScrollableBlock>
            </>
          ) : materialData.simulationLink ? (
            <>
              <S.ScrollableBlock key={`${investigationId}-simulation`}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%'
                }}>
                  <img
                    src={materialData.qrLink}
                    alt="Material Image"
                    style={{
                      width: '60%',
                      height: '60%',
                      objectFit: 'cover',
                    }}
                  />
                </div>

                <a
                  href={materialData?.simulationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'block',
                    textAlign: 'center',
                    marginTop: '15px',
                    color: '#007bff',
                    textDecoration: 'underline',
                  }}
                >
                  Open QR link in New Tab
                </a>
              </S.ScrollableBlock>
            </>
          ) : materialData.materialLink ? (
            <>
              <S.ScrollableBlock key={`${investigationId}-material`}>
                <img
                  src={materialData.materialLink}
                  alt="Material Image"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    alignContent: 'center'
                  }}
                />
              </S.ScrollableBlock>

            </>
          ) : (
            <S.ErrorBlock>
              <S.NoDataMessage>No materials required for this Investigation</S.NoDataMessage>
            </S.ErrorBlock>
          )
        ) : (
          <S.ErrorBlock>
            <S.NoDataMessage>No materials required for this Investigation</S.NoDataMessage>
          </S.ErrorBlock>
        )}

        {isOverlayVisible && (
          <S.Overlay
          >
            <S.OverlayContent>
              <S.CloseButton onClick={toggleOverlay}>×</S.CloseButton>
              <h2 style={{ textAlign: 'center', marginBottom: '10px' }}></h2>
              <img
                src={materialData?.qrLink}
                alt="QR Code"
                style={{
                  display: 'block',
                  margin: '0 auto',
                  width: '160px',
                  height: '160px',
                }}
              />
              <a
                href={materialData?.simulationLink}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  display: 'block',
                  textAlign: 'center',
                  marginTop: '15px',
                  color: '#007bff',
                  textDecoration: 'underline',
                }}
              >
                Open QR link in a New Tab
              </a>
            </S.OverlayContent>
          </S.Overlay>
        )}
      </S.TableContainer>
      <S.ActionButtonsWrapper
        style={{
          marginTop: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {shopifyId && <ShopifyWrapper variantId={shopifyId} product={product?.getShopifyProductByHandle} />}
        {(materialData?.simulationLink) && (materialData?.materialLink) && <button
          style={{
            fontSize: '14px',
            borderRadius: '3px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            cursor: 'pointer',
          }}
          onClick={toggleOverlay}
        >
          View Simulation
        </button>}
      </S.ActionButtonsWrapper>

    </div>



  );


};

export default InvestigationCard;
