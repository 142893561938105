import { FaShoppingCart } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
`;

export const Logo = styled.div`
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #000000;
  cursor: pointer;

  img {
    height: 40px;
    margin-right: 10px;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  gap: 30px; 

  .dashboard-link, .account-link, .cart-link {
    color: #000000;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;

    &:hover {
      color: #007bff;
    }
  }

  .icon {
    font-size: 20px;
    color: #000000;
    cursor: pointer;

    &:hover {
      color: #007bff;
    }
  }
`;

export const AccountDropdown = styled.div`
  position: relative;
  display: inline-block;

  &:hover .dropdown-content {
    display: block;
  }
`;

export const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px; 
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  right: 0; 

  a, .logout-button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    white-space: nowrap; 

    &:hover {
      background-color: #f1f1f1;
    }

    .icon {
      margin-right: 10px;
    }
  }

  .logout-button {
    background: none;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
`;
export const BlueIcon = styled(FaShoppingCart)`
color: blue;
`;

export const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
  color: black; // Default color

  &.active {
    ${BlueIcon} {
      color: blue; // Active color
    }
  }

  &:hover {
    text-decoration: underline; // Optional hover effect
  }
`;