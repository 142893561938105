import React from 'react';
import { useHistory } from 'react-router-dom';
import * as S from './style';
import { FaCheck } from 'react-icons/fa';
import Header from '../LoginNav';
import cleverC from '../../../../assets/cleverC.png';
import googleClassroom from '../../../../assets/google-classroom.svg';
import classLinkLogoSrc from '../../../../assets/classlink.png';
import canvas from '../../../../assets/canvas.png';
import { History } from 'history';

const FeatureItem = ({ text, color, fontSize = '1.5rem' }: { text: string; color: string; fontSize?: string }) => (
  <li>
    <FaCheck
      style={{
        backgroundColor: `${color}80`,
        minWidth: '25px',
        minHeight: '25px',
        borderRadius: '50%',
        color: color,
        padding: '5px',
        fontSize: fontSize,
      }}
    />{' '}
    {text}
  </li>
);

const IndividualAccount = ({ history }: { history: History }) => (
  <S.IndividualWrapper>
    <S.Card>
      <S.Heading style={{ marginBottom: '-0.2rem' }}>INDIVIDUAL</S.Heading>
      <S.SubHeading style={{ fontSize: '0.8rem' }}>Account for Teachers</S.SubHeading>
      <S.SubHeading>
        Features for <span style={{ color: '#4367E9', fontWeight: 'bold' }}>TEACHERS</span>
      </S.SubHeading>
      <S.Line></S.Line>
      <S.FeatureList>
        <FeatureItem text="Download students handouts for all investigations in a purchased book" color="#4367E9" />
        <FeatureItem text="Presentation tool for the teacher (no need to make slide decks)" color="#4367E9" />
        <FeatureItem text="Show students investigation videos to the class during different stages" color="#4367E9" />
        <FeatureItem text="Links to any needed investigation simulations" color="#4367E9" />
        <FeatureItem text="Lists of required materials for all investigations in a purchased book" color="#4367E9" />
        <FeatureItem text="Lists of aligned standards for multiple subjects and states" color="#4367E9" />
      </S.FeatureList>
      <S.PriceWrapper>
        <S.Price>FREE</S.Price>
        <span>with purchase of a book.</span>
      </S.PriceWrapper>
    </S.Card>
    <S.Button onClick={() => history.push('/?active=signup')}>SIGN UP NOW</S.Button>
  </S.IndividualWrapper>
);

const OrganizationAccount = () => (
  <S.OrgranizationWrapper>
    <S.Card>
      <S.Heading style={{ marginBottom: '-0.2rem' }}>ORGANIZATION</S.Heading>
      <S.SubHeading style={{ fontSize: '0.8rem' }}>Account for Districts or Schools</S.SubHeading>
      <S.CardContentWrapper>
        <div>
          <S.SubHeading>
            Features for<span style={{ color: '#20BC89', fontWeight: 'bold' }}> STUDENTS </span>
          </S.SubHeading>
          <S.Line></S.Line>
          <S.FeatureList>
            <FeatureItem text="Complete investigations online" color="#20BC89" />
            <FeatureItem text="Complete assessments online" color="#20BC89" />
            <FeatureItem text="Peer review system" color="#20BC89" />
            <FeatureItem text="Immersive reader tool" color="#20BC89" />
            <FeatureItem text="Discussion tools" color="#20BC89" />
            <FeatureItem text="No need to remember passwords" color="#20BC89" />
          </S.FeatureList>
        </div>
        <div>
          <S.SubHeading>
            Features for <span style={{ color: '#4367E9', fontWeight: 'bold' }}>TEACHERS</span>
          </S.SubHeading>
          <S.Line></S.Line>
          <S.FeatureList>
            <FeatureItem text="All features included with the individual account without purchasing a book" color="#4367E9" />
            <FeatureItem text="Create up to 8 classes and add 40 students and 2 TAs per class" color="#4367E9" />
            <FeatureItem
              text="Access to full library of investigations for science, math, and engineering"
              color="#4367E9"
            />
            <FeatureItem text="Access to full assessment library" color="#4367E9" />
            <FeatureItem text="Report scoring system and performance insights tool" color="#4367E9" />
            <FeatureItem text="Assessment scoring and feedback system including performance insights" color="#4367E9" />
          </S.FeatureList>
        </div>
        <div>
          <S.SubHeading>
            Features for <span style={{ color: '#A565A7', fontWeight: 'bold' }}> LEADERS</span>
          </S.SubHeading>
          <S.Line></S.Line>
          <S.FeatureList>
            <FeatureItem text="Add and remove users as needed" color="#A565A7" />
            <FeatureItem text="Transfer classes between teachers" color="#A565A7" />
            <FeatureItem text="Organize users with customizable tags" color="#A565A7" />
            <FeatureItem text="Teacher and student reports" color="#A565A7" />
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '0.5rem' }}>
              <FeatureItem text="Rostering and SSO included" color="#A565A7" />
              <div style={{ display: 'flex', gap: '2rem', padding: '0.5rem' }}>
                <S.IconWrapper>
                  <img src={canvas} alt="canvas" />
                  <img src={googleClassroom} alt="class" />{' '}
                </S.IconWrapper>
                <S.IconWrapper>
                  <img src={cleverC} alt="cleverC" /> <img src={classLinkLogoSrc} alt="classroom" />{' '}
                </S.IconWrapper>
              </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '2rem' }}>
              <FeatureItem text="LMS integration" color="#A565A7" />
              <S.IconWrapper>
                <img src={canvas} alt="canvas" />
                <img src={googleClassroom} alt="class" />{' '}
              </S.IconWrapper>
            </div>
          </S.FeatureList>
        </div>
      </S.CardContentWrapper>
      <S.PriceWrapper>
        <S.Price>
          $349.00<span>/teacher for 12 month of access</span>
        </S.Price>
        <span>
          *We also offer per student pricing.{' '}
          <a
            href="https://share.hsforms.com/1Vs6PCxm9RNqSIXLU3xvdPw5gbsq"
            style={{ textDecoration: 'underline' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click here
          </a>{' '}
          to request a quote
        </span>
      </S.PriceWrapper>
    </S.Card>
    <a href="https://share.hsforms.com/15izCGqjZR6G8zmsR8JJVUA5gbsq" target="_blank" rel="noopener noreferrer">
      <S.Button style={{ backgroundColor: 'transparent', color: '#4367E9' }}> REQUEST A QUOTE</S.Button>
    </a>
  </S.OrgranizationWrapper>
);

const TeacherAccountPage = () => {
  const history = useHistory();

  return (
    <>
      <Header label="Go Back" />
      <S.PageContainer>
        <S.CardContainer>
          <IndividualAccount history={history} />
          <OrganizationAccount />
        </S.CardContainer>
      </S.PageContainer>
    </>
  );
};

export default TeacherAccountPage;
