import React from 'react';
import { Modal, Button, Tag } from 'antd';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const CoverImage = styled.img`
  width: 200px;
  border-radius: 8px;
  margin-right: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InfoSection = styled.div`
  flex-grow: 1;
`;
const DescriptionContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  margin-top: 16px;
`;

const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
`;

const BadgeWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
`;

const Description = styled.p`
  font-size: 14px;
  color: #666;

  line-height: 1.6;
  margin-top: 16px;
`;

const StyledButton = styled(Button)`
  background-color: #4a6cf7;
  color: white;
  font-weight: bold;
  align-content: flex-end;
  justify-content: flex-end;
  display: table;
  margin-left: auto;
  margin-top: 20px;
  &:hover {
    background-color: #3b5acc;
  }
`;

interface BookModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  selectedBook?: any;
}

const BookModal: React.FC<BookModalProps> = ({ isModalVisible, closeModal, selectedBook }) => {
  const history = useHistory();
  return (
    <Modal title={null} visible={isModalVisible} onCancel={closeModal} footer={null} width={800}>
      {selectedBook && (
        <ContentWrapper>
          <CoverImage src={selectedBook.coverImage} alt={selectedBook.title} />
          <InfoSection>
            <Title>{selectedBook.title}</Title>
            <BadgeWrapper>
              {selectedBook.grade !== null && (
                <Tag style={{ color: 'green', borderRadius: '10px' }} color="green">
                  {` ${selectedBook.grade} Grade`}
                </Tag>
              )}
              <Tag style={{ color: 'blue', borderRadius: '10px' }} color="blue">
                {selectedBook.investigations?.length || 0} Investigations
              </Tag>
            </BadgeWrapper>
            <DescriptionContainer>
              <Description dangerouslySetInnerHTML={{ __html: selectedBook?.description || '' }} />
            </DescriptionContainer>
            <StyledButton
              onClick={() => {
                history.push(`/bookinvestigation/${selectedBook.id}`);
              }}
            >
              View Investigations
            </StyledButton>
          </InfoSection>
        </ContentWrapper>
      )}
    </Modal>
  );
};

export default BookModal;
