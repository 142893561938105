import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

`;

export const ModalContainer = styled.div`
  background: white;
  border-radius: 8px;
  max-width: 70vw;
  width: 100%;
  padding: 2rem;
  text-align: center;
  @media(max-height : 700px){
    height: 90vh;
    }
    @media(max-height : 650px){
    height: auto;
    }
`;

export const Header = styled.header`
  display: flex;
  justify-content: center;
  img {
    height: 80px;
  }
`;

export const ContentContainer = styled.div`
  margin-top: 1.5rem;
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
`;

export const SubTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  color: #333;
  margin-top: 1rem;
`;

export const BodyText = styled.p`
  color: #666;
  line-height: 1.6;
  margin-top: 1rem;
`;

export const FooterText = styled.p`
  color: #666;
  line-height: 1.6;
  margin-top: 1.5rem;
  padding: 2rem;
  a {
    color: #4367e9;
    text-decoration: underline;
  }
  @media(max-height:750px){
    padding:1rem;
    margin-top:1rem;
  }
  @media(max-height : 640px){
    margin-top: 0.4rem;
    padding: 0.5rem;

    }
`;

export const ButtonContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  @media (max-height: 750px) {
    margin-top: 1rem;
  }
  @media(max-height : 640px){
    margin-top: 0.6rem;
    }
`;

export const PrimaryButton = styled.button`
  background-color: #4367e9;
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    background-color: #3652a3;
  }
`;
