import React from 'react'
import Header from '../LandingHeader'
//import Footer from '../LandingFooter'
import VideoArea from './Videoarea'

const Videolib = () => {
  return (
    <div>
      <Header />
      <VideoArea />
    </div>
  )
}

export default Videolib
