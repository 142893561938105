import React, { useCallback, useState, useRef, useEffect } from 'react';
import * as S from './styles';
import { ShoppingCartOutlined, UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import adiLearningHubSrc from '../../../assets/adi-learning-hub.svg';
import { MdEditDocument, MdFavoriteBorder } from 'react-icons/md';
import { CgMicrosoft } from 'react-icons/cg';
import { useAuth } from '../../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { RiAdminFill } from 'react-icons/ri';
import { CiShoppingCart } from 'react-icons/ci';
import StyleSwitch from './StyledSwitch';
import { IoIosArrowDown } from 'react-icons/io';

const BookHeader = () => {
  const { signOut, isAdiSuperAdmin } = useAuth();
  const history = useHistory();
  const [isHovered, setIsHovered] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setIsHovered(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  const handleLogout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut]);
  return (
    <S.HeaderContainer>
      <S.Logo>
        <img src={adiLearningHubSrc} alt="Adi Learning Hub Logo" onClick={() => history.push('/')} />
      </S.Logo>
      <div ref={containerRef}>
          <div onMouseEnter={() => setIsHovered(true)} onClick={() => setIsHovered(!isHovered)} >
            <IoIosArrowDown cursor={'pointer'} style={{ fontSize: '32px'  ,position: 'absolute', top: '47px', left:'50%', border: '2px solid #000000', borderRadius: '40px' , padding: '5px' }} />
            {isHovered && <StyleSwitch top='90px' type='book' />}
          </div>
        </div>
      <S.NavMenu>
        <Link to="/booklib" className="dashboard-link">
          {' '}
          <CgMicrosoft size={30} style={{ marginRight: '8px', verticalAlign: 'middle' }} />
          Dashboard
        </Link>
        <Link to="/cart" className="cart-link">
          {' '}
          <CiShoppingCart size={30} style={{ marginRight: '8px', verticalAlign: 'middle' }} />
          Cart
        </Link>

        {/* <BellOutlined className="icon" /> */}
        {/* <ShoppingCartOutlined className="icon" /> */}
        <S.AccountDropdown>
          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <UserOutlined className="icon" />
            My Account
          </div>
          <S.DropdownContent className="dropdown-content">
            <Link to="/bookprofile">
              <UserOutlined className="icon" />
              My Profile
            </Link>
            {isAdiSuperAdmin && (
              <Link to="/bookadmin">
                <RiAdminFill className="icon" />
                Admin
              </Link>
            )}
            <Link to="/bookorderlist">
              <ShoppingCartOutlined className="icon" />
              My Orders
            </Link>
            <Link to="/bookquoteList">
              <MdEditDocument size={20} style={{ marginRight: '8px' }} />
              My Quotes
            </Link>
            <Link to="/bookWishList">
              <MdFavoriteBorder size={20} style={{ marginRight: '8px' }} />
              My Favorites
            </Link>
            <button className="logout-button" onClick={handleLogout}>
              <UserOutlined className="icon" />
              Logout
            </button>
          </S.DropdownContent>
        </S.AccountDropdown>
      </S.NavMenu>
    </S.HeaderContainer>
  );
};

export default BookHeader;
