import styled from 'styled-components';

const sharedStyles = `
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #f9f9f9;
`;

export const Section = styled.div`
  ${sharedStyles}
  margin-top: 60px;
`;

export const ContentSection = styled.div`
  ${sharedStyles}
  flex-direction: column;
  align-items: center;
  text-align: justify;
  margin-top: 60px;
`;

export const LeftSide = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 50%;
  }
`;

export const RightSide = styled.div`
  flex: 2;
  padding-left: 20px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Content = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: #333;
`;
export const ProfileTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;