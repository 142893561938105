import styled from 'styled-components';

interface ToggleContainerProps {
  top: string;
}

export const ToggleContainer = styled.div<ToggleContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 99;
  top: ${(props) => props.top};
  right:36vw;
  background-color: #fff;
  width: 380px;
  height: 30px;
  border-radius: 40px;
  transition: all 500ms;
  @media (max-width: 1200px) {  
    right: 30vw;
  }
`;

export const ToggleButton = styled.button<{ isActive: boolean }>`
  flex: 1;
  height: 30px;
  max-width: 100%; /* Ensure the button does not grow too large */
  border: ${({ isActive }) => (isActive ? '2px solid #4367E9' : '2px solid #767676')};
  background-color: ${({ isActive }) => (isActive ? '#4285F440' : 'transparent')};
  color: ${({ isActive }) => (isActive ? '#4367E9' : '#767676')};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin: 0;
  padding: 0 10px;
  transition: all 500ms;
  box-sizing: border-box;
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent text from wrapping */
  display: flex;
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
`;
