import { gql } from '@apollo/client';

const BOOK_QUERIES = {
  GetBooksList: gql`
    query GetBooksList($offset: Int, $limit: Int) {
      fetchBooks(offset: $offset, limit: $limit) {
        id
        name
        isbn
        imageUrl
        locked
        tags {
          id
          tag
          color
        }
        createdAt
        updatedAt
      }
    }
  `,
  GetAllShopifyProducts: gql`
    query GetAllShopifyProducts($page: Int, $pageSize: Int, $filter: ShopifyProductFilter) {
      getAllShopifyProducts(page: $page, pageSize: $pageSize, filter: $filter) {
        id
        shopifyProductId
        title
        description
        price
        status
        media
        category
        type
        barcode
        createdAt
        updatedAt
      }
    }
  `,

  GetUnusedCodes: gql`
    query GetUnusedCodes($bookId: String!, $limit: Int = 20) {
      getUnusedCodes(bookId: $bookId, limit: $limit) {
        id
        code
        used
      }
    }
  `,
  FetchBookByBookCode: gql`
    query FetchBookByBookCode($id: String) {
      fetchBookByBookCode(id: $id) {
        id
        title
        subject
        standard
        state
        isbn
        imageUrl
        locked
        investigation
      }
    }
  `,

  //type userBooks{
  // totalBooks:Int
  // bookResponse:[BookResponse]
  // }
  FetchUserBooks: gql`
    query FetchUserBooks($page: Int, $filter: shopifyFilter, $search: String) {
      fetchUserBooks(page: $page, filter: $filter, search: $search) {
        totalBooks
        bookResponse {
          id
          title
          subject
          investigation
          standard
          description
          state
          isbn
          imageUrl
          locked
          tags {
            id
            name
            color
            category
          }
          investigations {
            id
            title
            description
            imageUrl
            kitUrl
            suppliesUrl
            standards {
              id
              abbreviatedStatement
              fullStatement
              code
              grade
              state
              lastChangeDateTime
              discipline
            }
            nextGenerationStandards {
              id
              abbreviatedStatement
              fullStatement
              code
              grade
              state
              lastChangeDateTime
              discipline
            }
          }
        }
      }
    }
  `,

  FetchAllBooks: gql`
    query FetchAllBooks($page: Int, $search: String, $filter: shopifyFilter) {
      fetchAllBooks(page: $page, search: $search, filter: $filter) {
        totalBooks
        bookResponse {
          id
          title
          subject
          investigation
          standard
          price
          state
          isbn
          imageUrl
          locked
          tags {
            id
            name
            color
            category
          }
          investigations {
            id
            title
            description
            imageUrl
            kitUrl
            suppliesUrl
            standards {
              id
              abbreviatedStatement
              fullStatement
              code
              grade
              state
              lastChangeDateTime
              discipline
            }
            nextGenerationStandards {
              id
              abbreviatedStatement
              fullStatement
              code
              grade
              state
              lastChangeDateTime
              discipline
            }
          }
        }
      }
    }
  `,

  FetchBookById: gql`
    query FetchBookById($id: String!) {
      fetchBookById(id: $id) {
        id
        title
        subject
        description
       kit {
            title
            id
        }
        price
        standard
        state
        isbn
        imageUrl
        shopifyProductId
        locked
        investigations {
          id
          title
          description
          imageUrl
          kitUrl
          suppliesUrl
        }
        product {
          id
          shopifyProductId
          title
          description
          price
          status
          media
          category
          type
          barcode
          createdAt
          updatedAt
          variantId
        }
      }
    }
  `,
  FetchSimilarBooks: gql`
    query GetAllBooksByRecommendation($page: Int, $bookId: String) {
      getAllBooksByRecommendation(page: $page, Refbook: $bookId) {
        totalBooks
        bookResponse {
          id
          title
          subject
          investigation
          standard
          state
          isbn
          imageUrl
          locked
          investigations {
            id
            title
            description
            imageUrl
            kitUrl
            suppliesUrl
          }
        }
      }
    }
  `,

  FetchWishlist: gql`
    query GetWishlist($page: Int, $limit: Int) {
      getWishlist(page: $page, limit: $limit) {
        totalItems
        wishlist {
          id
          investigationId
          investigationName
          createdAt
          investigations {
            id
            title
            description
            imageUrl
            kitUrl
            suppliesUrl
            standards {
              id
              abbreviatedStatement
              fullStatement
              code
              grade
              state
              lastChangeDateTime
              discipline
            }
            nextGenerationStandards {
              id
              abbreviatedStatement
              fullStatement
              code
              grade
              state
              lastChangeDateTime
              discipline
            }
          }
        }
      }
    }
  `,

  FetchInvMaterial: gql`
    query FetchInvestigationMaterial($investigationId: String) {
      fetchInvestigationMaterial(investigationId: $investigationId) {
        item
        typeOfMaterial
        quantityPerGroup
        quantityForEntireClass
      }
    }
  `,

  getInvestigationMaterial: gql`
    query GetInvestigationMaterial($investigationId: String) {
      getInvestigationMaterial(investigationId: $investigationId){
      materialLink
      simulationLink
      qrLink
      }
    }
  `,
  getNode: gql`
    query getNode {
      getNode {
        id
        data
        node
      }
    }
  `,
  chatGptPrompt: gql`
    query chatGptPrompt($prompt: String) {
      chatGptPrompt(prompt: $prompt)
    }
  `,
  checkBookOwner: gql`
   query CheckBookOwner($investigationId:String) {
    checkBookOwner(investigationId: $investigationId)
}

  `,
};

export default BOOK_QUERIES;