import React from 'react';
import * as S from './styles';

const FounderContent = () => {
  return (
    <>
      <S.ContentSection>
        <S.Title>Our Leadership</S.Title>
        <S.Content>
          {' '}
          ADI is led by Dr. Victor Sampson, creator of the Argument-Driven Inquiry model and a renowned educational
          researcher, and Krista Sampson, a former elementary teacher and ADI’s founder. Together, they bring over two
          decades of expertise and passion for transforming education. With a commitment to empowering educators and
          supporting diverse learners, their leadership drives ADI’s mission to provide innovative, research-based
          instructional materials that make a lasting impact in classrooms worldwide.
        </S.Content>
      </S.ContentSection>
      <S.Section>
        <S.LeftSide>
          <img src="https://placehold.co/600x400" alt="Founder" />
        </S.LeftSide>
        <S.RightSide>
          <S.Title>Dr. Victor Sampson</S.Title>
          <S.ProfileTitle>Helo this is title of the profile</S.ProfileTitle>
          <S.Content>
            {' '}
            Victor Sampson is an Associate Professor of STEM (science, technology, engineering, and mathematics)
            education at The University of Texas at Austin (UT-Austin). He received a BA in zoology from the University
            of Washington, an MIT from Seattle University, and a PhD in curriculum and instruction with a specialization
            in science education from Arizona State University. Victor also taught high school biology and chemistry for
            nine years in public schools. He is an expert in argumentation and three-dimensional instruction in science
            education, teacher learning, and assessment. He is also an expert in the design and development of new
            educational apps. Victor has published 55 research articles in the field of STEM education. While at Florida
            State University (2007-2014) and University of Texas (2014-current) he served as the PI or a Co-PI on 6
            large research projects that were funded by either the National Science Foundation (NSF) or the Institute of
            Education Sciences (IES). Victor has received several awards for his scholarship, including the 2008
            National Association for Research in Science Teaching (NARST) Outstanding Dissertation award and the 2012
            NARST Early Career award. He is also a National Science Teaching Association (NSTA) Fellow.
          </S.Content>
        </S.RightSide>
      </S.Section>
      <S.Section>
        <S.RightSide>
          <S.Title>Krista Sampson</S.Title>
          <S.Content>
            {' '}
            Krista Sampson is the founder, majority owner, and CEO of Argument-Driven Inquiry (ADI), a STEM education
            company she launched in 2015. With a mission to transform science, engineering, and mathematics education,
            ADI provides innovative, rigorous, and equitable instructional materials alongside high-quality professional
            development for teachers. Since its inception, ADI has impacted over 100,000 teachers across 800+ school
            districts in 40 + states, supporting educators in delivering accessible and meaningful STEM learning
            experiences. Krista began her career as an elementary teacher, bringing hands-on learning to high-need
            schools in Seattle, Washington, and Phoenix, Arizona. Her dedication to equitable education fueled her
            transition into educational business management, where she now drives ADI’s growth and commitment to
            supporting teachers. Under her leadership, ADI has become a recognized leader in STEM education, and her
            entrepreneurial achievements have been celebrated, including recognition on the Forbes Next 1000 list in
            2021 and the 2022 Austin Woman’s Way Business Award in STEM. A graduate of Washington State University,
            Krista’s journey from classroom teacher to CEO reflects her unwavering commitment to improving STEM
            education and empowering teachers nationwide.
          </S.Content>
        </S.RightSide>
        <S.LeftSide>
          <img src="https://placehold.co/600x400" alt="Founder" />
        </S.LeftSide>
      </S.Section>
    </>
  );
};

export default FounderContent;
