import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, message, Empty } from 'antd';
import * as S from './styles';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { gqlSchema } from '../../../../gql/schema';
import Button from '../../../../shared/Button';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import LibrarySkeleton from '../../Library/LibrarySkeleton';

const { Title } = Typography;

interface Book {
  id: number;
  title: string;
  grade: string;
  coverImage: string;
}
interface BookRecommendationProps {
  bookId: string;
}
const BookRecommendation: React.FC<BookRecommendationProps> = ({ bookId }) => {
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading, refetch } = useQuery(gqlSchema.BookSchema.queries.FetchSimilarBooks, {
    variables: {
      bookId,
      page: currentPage,
    },
    onError: (error) => {
      message.error(`Error in fetching book recommendations - ${error.message || 'Unexpected Error'}`);
    },
  });

  useEffect(() => {
    refetch({
      bookId: bookId,
      page: currentPage,
    });
  }, [currentPage, bookId, refetch]);

  const booksData: Book[] = data?.getAllBooksByRecommendation?.bookResponse?.map((book: any) => ({
    id: book.id,
    title: book.title,
    description: book.description,
    price: book.price,
    coverImage: book.imageUrl || '/path/to/default/image.jpg',
  }));

  const handlePageChange = (page: number) => setCurrentPage(page);
  const totalPages = Math.ceil(data?.getAllBooksByRecommendation.totalBooks / 4);
  return (
    <S.Container>
      <Row align="middle" style={{ marginBottom: '32px' }}>
        <Col>
          <Title level={2}>Frequently Recommended Similar Books</Title>
        </Col>
        <Row style={{ marginLeft: 'auto', gap: '8px' }}>
          <Col style={{ margin: 'auto' }}>
            <S.PrevButton
              disabled={currentPage <= 1 || !booksData || booksData.length === 0}
              onClick={() => (currentPage > 1 ? handlePageChange(currentPage - 1) : null)}
            >
              <FaArrowLeft size={12} />
            </S.PrevButton>
          </Col>

          <Col style={{ margin: 'auto' }}>
            <S.NextButton
              disabled={currentPage >= totalPages || !booksData || booksData.length === 0}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <FaArrowRight size={12} />
            </S.NextButton>
          </Col>
        </Row>
      </Row>

      {/* Book Cards */}
      {booksData && booksData.length > 0 ? (
        <Row gutter={[40, 16]}>
          {loading ? (
            <LibrarySkeleton row={1} />
          ) : (
            booksData.map((book) => (
              <Col key={book.id} xs={24} sm={12} md={6}>
                <S.BookCard>
                  <S.ImageWrapper>
                    <img src={book.coverImage} alt={book.title} />
                  </S.ImageWrapper>
                  <S.BookTitle>{book.title}</S.BookTitle>
                  <Button
                    text="View"
                    style={{ backgroundColor: '#4367E9', color: '#fff', marginTop: 'auto' }}
                    onClick={() => {
                      history.push(`/bookPage/${book.id}`);
                      window.scrollTo(0, 0);
                    }}
                    block
                  />
                </S.BookCard>
              </Col>
            ))
          )}
        </Row>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No investigation found"
          style={{ marginTop: '20px', alignSelf: 'center' }}
        />
      )}
    </S.Container>
  );
};

export default BookRecommendation;
