import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  max-width: 900px;
  min-height: 80vh;
  width: 100%;
  padding: 2rem;
@media (max-height: 730px) {  
 height: auto;
 padding: 1rem;
}
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  display:none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;


export const Header = styled.header`
  display: flex;
  justify-content: center;              
  align-items: center;
  img {
    height: 150px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media(max-height: 730px){
      height: 125px;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`;

export const ContentContainer = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;  


  @media (max-width: 768px) {
    padding: 5rem 1rem 1rem;
  }
`;

export const Title = styled.h2`
  color: #333;
  font-size: 1.5rem;
  align-self: center;
  font-weight: 700;
  @media (max-height: 730px) {
    font-size: 1.2rem;
  }
`;

export const BodyText = styled.p`
  line-height: 1.6;
  font-weight: 400;
  @media (max-height: 730px) {
    font-size: 0.8rem;
  }
`;

export const FeatureList = styled.div`
@media (max-height: 730px) {
  font-size: 0.8rem;
}

`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  gap: 1rem;
  margin-left: 1rem;

`;

export const FooterText = styled.p`
  line-height: 1.6;
  font-weight: 400;
  margin-bottom: 2rem;
  @media (max-height: 730px) {
    font-size: 0.8rem;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 2rem;
  align-self: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const BaseButton = styled.button`
  padding: 0.8rem 1.5rem;
  border-radius: 15px;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.2s;
`;

export const PrimaryButton = styled(BaseButton)`
  background-color: #4367e9;
  color: white;
  border: none;

  &:hover {
    background-color: #3652a3;
  }
`;

export const SecondaryButton = styled(BaseButton)`
  background-color: transparent;
  border: 3px solid #4367e9;
  color: #4367e9;

  &:hover {
    background-color: #f1f4f8;
  }
`;
