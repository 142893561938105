import React, { useState, useEffect, useRef } from 'react';
import * as S from '../style';
import SignIn from '../Signin';
import SignUp from '../Signup';
import whiteAdiLogo from '../../../../assets/booklanding/white-adi.png';
import logo from '../../../../assets/adi-learning-hub.svg';
import { useLocation } from 'react-router-dom';

const SignUpAlternatePanel = ({ onActionBtnClick, transitionState }: any) => {
  return (
    <>
      <S.Heading $color="#fff">Howdy! Nice to meet you. </S.Heading>
      <S.Body $color="#fff">Don’t have an account with us yet? Click below to create one.</S.Body>
      <S.ButtonImageWrapper>
        <S.Button
          $background="#fff"
          $foreground="#4367e9"
          disabled={transitionState === 'transitioning'}
          onClick={onActionBtnClick.bind(null, 'signup')}
        >
          SIGN UP
        </S.Button>
        <img src={whiteAdiLogo} alt="adi-logo" />
      </S.ButtonImageWrapper>
    </>
  );
};
const SignUpPanel = ({ onActionBtnClick, transitionState }: any) => {
  return (
    <>
      <img src={logo} alt="adi-logo" />
      <S.Body $color="#000" style={{ fontWeight: 500 }}>
        Never been here before? Click sign up to get started.
      </S.Body>
      <S.Heading $color="#000">Create an Account</S.Heading>
      <S.ButtonImageWrapper>
        <S.Button
          $background="#4367e9"
          $foreground="#fff"
          disabled={transitionState === 'transitioning'}
          onClick={onActionBtnClick.bind(null, 'signup')}
        >
          SIGN UP NOW
        </S.Button>
      </S.ButtonImageWrapper>
    </>
  );
};

const SignInPanel = ({ onActionBtnClick, transitionState }: any) => {
  return (
    <>
      <S.Heading $color="#fff">Welcome Back!</S.Heading>
      <S.Body $color="#fff">Already have an account? Click sign in to use the Learning Hub.</S.Body>
      <S.ButtonImageWrapper>
        <S.Button
          $background="#fff"
          $foreground="#4367e9"
          disabled={transitionState === 'transitioning'}
          onClick={onActionBtnClick.bind(null, 'signin')}
        >
          SIGN IN
        </S.Button>
        <img src={whiteAdiLogo} alt="adi-logo" />
      </S.ButtonImageWrapper>
    </>
  );
};

const AuthPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryObject: { [key: string]: string } = {};
  queryParams.forEach((value, key) => {
    queryObject[key] = value;
  });
  const queryActive = (queryObject.active?queryObject.active:'initial') as 'signin' | 'signup' | 'initial';

  
  const [activePanel, setActivePanel] = useState<'signin' | 'signup' | 'initial'>(queryActive ? queryActive : 'initial');
  const [leftSlideDirection, setLeftSlideDirection] = useState<'center' | 'slide-left' | 'slide-right'>('center');
  const [rightSlideDirection, setRightSlideDirection] = useState<'center' | 'slide-left' | 'slide-right'>('center');
  const [leftZIndex, setLeftZIndex] = useState<0 | 1>(0);
  const [rightZIndex, setRightZIndex] = useState<0 | 1>(0);
  const [leftPanelColor, setLeftPanelColor] = useState<'#fff' | '#4367e9'>(queryActive==='signin' ? '#4367e9' : '#fff');
  const [rightPanelColor, setRightPanelColor] = useState<'#fff' | '#4367e9'>(queryActive==='signin' ? '#fff' : '#4367e9');
  const [transitionState, setTransitionState] = useState<'start' | 'transitioning' | 'end' | 'idle'>('idle');
  const transitionPanelRef = useRef<HTMLDivElement | null>(null);

  const triggerTransition = (nextActivePanel: 'signin' | 'signup',activePanel:any) => {
    setRightSlideDirection('slide-right');
    setLeftSlideDirection('slide-left');
    setTimeout(() => {
      setLeftPanelColor(nextActivePanel === 'signin' ? '#4367e9' : '#fff');
      setRightPanelColor(nextActivePanel === 'signin' ? '#fff' : '#4367e9');
      setActivePanel(nextActivePanel);
      setLeftSlideDirection('center');
      setRightSlideDirection('center');
      if (nextActivePanel === 'signup') {
        if (activePanel === 'initial') {
          setRightZIndex(0);
          setLeftZIndex(1);
        }
        else{

          setRightZIndex(1);
          setLeftZIndex(0);
        }
      }
      if (nextActivePanel === 'signin') {
        setRightZIndex(0);
        setLeftZIndex(1);
      }
    }, 450);
  };
  const handleActionBtnClick = (nextActivePanel: 'signin' | 'signup') => {
    triggerTransition(nextActivePanel,activePanel);
    if (activePanel === 'signin') {
      setRightZIndex(0);
      setLeftZIndex(1);
    } else {
      setRightZIndex(1);
      setLeftZIndex(0);
    }
  };
    useEffect(() => {
      if (queryActive !== activePanel){
        setActivePanel(queryActive);
        setLeftPanelColor(queryActive === 'signin' ? '#4367e9' : '#fff');
        setRightPanelColor(queryActive === 'signin' ? '#fff' : '#4367e9');


      }

    }, [queryActive]);

  useEffect(() => {
    const ref = transitionPanelRef;

    let runTranitionEventHandler = (event: TransitionEvent) => {
      if (event.propertyName === 'transform') {
        setTransitionState('start');
      }
    };

    let startTranitionEventHandler = (event: TransitionEvent) => {
      if (event.propertyName === 'transform') {
        setTransitionState('transitioning');
      }
    };

    let endTranitionEventHandler = (event: TransitionEvent) => {
      if (event.propertyName === 'transform') {
        setTransitionState('end');
        requestAnimationFrame(() => {
          setTransitionState('idle');
        });
      }
    };

    if (transitionPanelRef !== null && transitionPanelRef.current) {
      transitionPanelRef.current.addEventListener('transitionrun', runTranitionEventHandler);
      transitionPanelRef.current.addEventListener('transitionstart', startTranitionEventHandler);
      transitionPanelRef.current.addEventListener('transitionend', endTranitionEventHandler);
    }

    return () => {
      if (ref !== null && ref.current) {
        if (startTranitionEventHandler) {
          ref.current.removeEventListener('transitionstart', startTranitionEventHandler);
        }
        if (endTranitionEventHandler) {
          ref.current.removeEventListener('transitionend', endTranitionEventHandler);
        }
        if (runTranitionEventHandler) {
          ref.current.removeEventListener('transitionrun', runTranitionEventHandler);
        }
      }
    };
  }, []);

  return (
    <S.MainContainer>
      <S.TransitionContainer>
        <S.TransitionPanel
          $background={leftPanelColor}
          ref={transitionPanelRef}
          className={`${leftSlideDirection}`}
          style={{ zIndex: leftZIndex }}
        >
          {activePanel === 'initial' ? (
            <SignUpPanel onActionBtnClick={handleActionBtnClick} transitionState={transitionState} />
          ) : null}
          {activePanel === 'signin' ? (
            <SignUpAlternatePanel onActionBtnClick={handleActionBtnClick} transitionState={transitionState} />
          ) : null}
          {activePanel === 'signup' ? (
            <SignUp onActionBtnClick={handleActionBtnClick} transitionState={transitionState} />
          ) : null}
        </S.TransitionPanel>

        <S.TransitionPanel
          $background={rightPanelColor}
          style={{ zIndex: rightZIndex }}
          className={`${rightSlideDirection}`}
        >
          {activePanel === 'initial' ? (
            <SignInPanel onActionBtnClick={handleActionBtnClick} transitionState={transitionState} />
          ) : null}
          {activePanel === 'signin' ? (
            <SignIn onActionBtnClick={handleActionBtnClick} transitionState={transitionState} />
          ) : null}
          {activePanel === 'signup' ? (
            <SignInPanel onActionBtnClick={handleActionBtnClick} transitionState={transitionState} />
          ) : null}
        </S.TransitionPanel>
      </S.TransitionContainer>
    </S.MainContainer>
  );
};

export default AuthPage;
