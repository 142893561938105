import React from 'react';
import Login from './Login'
import Navbar from './LoginNav'

const LoginNew = () => {

  return (
    <>
    <Navbar label='Account Type' />
    <Login />
    </>
  );
};

export default LoginNew;