import styled from 'styled-components';
import { Input, Button } from 'antd';

export const PageContainer = styled.div`
  padding: 24px;
  max-width: 1500px;
  margin: 0 auto;
  .card-container{
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
   
  }
`;

export const CardGrid =  styled.div`
display: grid;
grid-template-columns: repeat(2, minmax(300px, 1fr));
gap: 30px;

&.fullview {
grid-template-columns: 1fr;
place-items: center;
}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const SearchInput = styled(Input)`
  width: 300px;
  border-radius: 8px;
`;

export const FavoriteButton = styled(Button)`
  background-color: #3f78e0;
  color: #ffffff;
  border-radius: 8px;
  &:hover {
    background-color: #336ac2;
    color: #ffffff;
  }
`;
export const NoDataMessage = styled.p`
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 24px;
  color: #666666;
`;