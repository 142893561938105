/* eslint-disable complexity */
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FaRegUserCircle } from 'react-icons/fa';
import { BiMessageDetail } from 'react-icons/bi';
import { FiUsers, FiTag, FiUpload, FiSettings, FiLogOut } from 'react-icons/fi';
import { MdPayment } from 'react-icons/md';
import { Menu } from 'antd';
import * as S from './styles';

interface ProfileMenuProps {
  menuVisible: boolean;
  isStudent: boolean;
  isTeacherAssistant: boolean;
  isMobile: boolean;
  isAdiSuperAdmin: boolean;
  user: {
    roles: string[];
    preferredSubscription?: string;
    preferredRole?: string;
    subscriptions?: { length: number };
  };
  handleRoleToggle: () => void;
  isGoogleStudent: boolean;
  isCanvasStudent: boolean;
  handleLogout: () => void;
  isSubscriptionUser: boolean;
  isAdiAdmin: boolean;
}

const ProfileMenu: React.FC<ProfileMenuProps> = ({
  menuVisible,
  isStudent,
  isTeacherAssistant,
  isMobile,
  isAdiSuperAdmin,
  user,
  handleRoleToggle,
  isGoogleStudent,
  handleLogout,
  isSubscriptionUser,
  isAdiAdmin,
  isCanvasStudent,
}) => {
  const history = useHistory();
  const subscriptionsLength = user?.subscriptions?.length;
  const preferredSubscription = user?.preferredSubscription;

  return useMemo(
    () => (
      <S.Menu $menuVisible={menuVisible}>
        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-myprofile"
          $menuVisible={menuVisible}
          icon={<FaRegUserCircle size={18} style={{ marginRight: 12 }} />}
          onClick={() => {
            history.push('/profile');
          }}
        >
          My Profile
        </S.MenuItem>
        <Menu.Divider style={{ margin: '4px 24px' }} />
        {!isStudent && !isTeacherAssistant && isMobile && !isAdiSuperAdmin && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-managetags"
              icon={<BiMessageDetail size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/messages');
              }}
            >
              My Messages
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {((user.roles.length > 1 && !user.roles.includes('non_enterprise')) ||
          user.roles.includes('organization_admin') ||
          user.roles.includes('adi_super_admin') ||
          user.roles.includes('adi_admin') ||
          user.roles.includes('google_teacher') ||
          user.roles.includes('canvas_teacher')) && (
          <>
            <S.MenuItem
              $menuVisible={menuVisible}
              onClick={handleRoleToggle}
              icon={<FiUsers size={18} style={{ marginRight: 12 }} />}
            >
              Change Role
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}       
        {!isStudent && !isGoogleStudent && !isCanvasStudent && !isTeacherAssistant && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-managetags"
              icon={<FiTag size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/manage-tags');
              }}
            >
              Manage Tags
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}
        {user?.subscriptions?.length !== undefined && user?.subscriptions?.length > 0 && (
          <>
            <S.MenuItem $menuVisible={menuVisible} icon={<FiUsers size={18} style={{ marginRight: 12 }} />}>
              {user?.preferredSubscription === 'user' ? 'User Subscription' : 'Organization Subscription'}
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {isSubscriptionUser && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-billing-information"
              icon={<MdPayment size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/billing-information');
              }}
            >
              Billing Information
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {(isAdiAdmin || isAdiSuperAdmin) && (
          <>
            <S.MenuItem
              $menuVisible={menuVisible}
              onClick={() => {
                history.push('/whitelist-domain');
              }}
              icon={<FiUsers size={18} style={{ marginRight: 12 }} />}
            >
              Whitelist domain
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {(isAdiAdmin || isAdiSuperAdmin) && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-uploadcsvs"
              icon={<FiUpload size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => history.push('/upload-csv')}
            >
              Upload CSVs
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        {isSubscriptionUser && (
          <>
            <S.MenuItem
              data-cy="shared-authenticatedheader-profilemenu-item-updatesubscription"
              icon={<FiSettings size={18} style={{ marginRight: 12 }} />}
              $menuVisible={menuVisible}
              onClick={() => history.push('/manage-subscription')}
            >
              Manage Subscription
            </S.MenuItem>
            <Menu.Divider style={{ margin: '4px 24px' }} />
          </>
        )}

        <S.MenuItem
          data-cy="shared-authenticatedheader-profilemenu-item-logout"
          icon={<FiLogOut size={18} style={{ marginRight: 12 }} />}
          $menuVisible={menuVisible}
          onClick={handleLogout}
        >
          Logout
        </S.MenuItem>
      </S.Menu>
      // eslint-disable-next-line react-hooks/exhaustive-deps
    ),
    [
      handleLogout,
      isSubscriptionUser,
      history,
      menuVisible,
      isStudent,
      isTeacherAssistant,
      user.roles,
      handleRoleToggle,
      isAdiAdmin,
      isAdiSuperAdmin,
      preferredSubscription,
      subscriptionsLength,
      isGoogleStudent,
      isMobile,
    ],
  );
};

export default ProfileMenu;
