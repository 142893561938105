import React from 'react';
import * as S from './styles';
import logo from '../../../../assets/logo.svg';
import { useHistory } from 'react-router-dom';
interface ButtonProps {
  label: string;
}
const Navbar = ({ label }: ButtonProps) => {
  const history = useHistory();
  return (
    <S.Navbar>
      <a href="https://adilearninghub.com/" target="_blank" rel="noopener noreferrer">
        <S.Logo src={logo} alt="Logo" />
      </a>
      <S.NavLinks>
        <a href="https://www.argumentdriveninquiry.com/" target="_blank" rel="noopener noreferrer">
          {' '}
          <S.Link>ADI Home</S.Link>
        </a>
        <a href="http://shop.argumentdriveninquiry.com" target="_blank" rel="noopener noreferrer">
        <S.Link>Store</S.Link>
        </a>
        <S.NavButton >
          <button onClick={() => history.push(`${label === 'Account Type' ? '/account-type' : '/'}`)}>{label}</button>
        </S.NavButton>
      </S.NavLinks>
    </S.Navbar>
  );
};
export default Navbar;
