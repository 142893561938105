import React, { useEffect, useState } from 'react';
import * as S from './styles';
import logo from '../../assets/adi-learning-hub.svg';
import { useAuth } from '../../hooks/useAuth';
import { gqlSchema } from '../../gql/schema';
import { useMutation } from '@apollo/client';

const SubscriptionUpdateModal = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const { user, updateUser } = useAuth();

  const [updateUserWelcomeValue] = useMutation(gqlSchema.BookSchema.mutations.updateUserWelcomeValue);
  useEffect(() => {
    if (user) {
      console.log('newWelcomeNote', user.newWelcomeNote);
      setModalVisible(user?.newWelcomeNote ? true : false);
    }
  }, [user?.newWelcomeNote]);

  const handleUpdate = async () => {
    try {
      await updateUserWelcomeValue({
        variables: { newWelcomeNoteValue: false },
      });
      setModalVisible(false);
      updateUser({ ...user, newWelcomeNote: false });
    } catch (error) {
      console.error('Error updating welcome note value:', error);
      setModalVisible(false);
    }
  };

  // const onClose = () => {}
  if (!modalVisible) return null;
  return (
    <S.ModalOverlay>
      <S.ModalContainer>
        <S.Header>
          <img src={logo} alt="Adi Learning Hub Logo" />
        </S.Header>
        <S.ContentContainer>
          <S.Title>Important Update: Changes to Our Subscription Plans</S.Title>
          <S.BodyText>
            Our Pro and Premium subscription plans for individual teachers are no longer available for purchase. If you
            currently have a Pro or Premium subscription, you will still be able to use it until your current
            subscription period ends, but after that, you will not be able to renew it.
          </S.BodyText>
          <S.BodyText>
            <a
              href="https://help.adilearninghub.com/knowledge"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#4367E9', textDecoration: 'underline' }}
            >
              {' '}
              Click here
            </a>{' '}
             for instructions on how to log in to your Pro or Premium features
          </S.BodyText>

          <S.SubTitle>Don’t worry! You will still have access to all the content you love.</S.SubTitle>
          <S.BodyText>
            Instead of subscribing to a plan, you’ll be able to purchase individual books and add them to your library.
            Once a book is in your library, you’ll still be able to download the ready-to-use student handouts and the
            presentation mode that includes the videos and color images for each investigation in your book. Our{' '}
            <strong>Organization Plan</strong> that includes SSO and LMS integration for districts and schools is also
            still available.
          </S.BodyText>
          <S.FooterText>
            Thank you for being part of our community! If you have any questions, feel free to reach out to our{' '}
            <a href="mailto:howdy@argumentdriveninquiry.com">support team</a>.
          </S.FooterText>
          <S.ButtonContainer>
            <S.PrimaryButton onClick={() => handleUpdate()}>THANKS FOR LETTING ME KNOW</S.PrimaryButton>
          </S.ButtonContainer>
        </S.ContentContainer>
      </S.ModalContainer>
    </S.ModalOverlay>
  );
};

export default SubscriptionUpdateModal;
